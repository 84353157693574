
import React from "react";
import Title from "../../components/Title";
import * as Request from "../../components/Call";
import OverzichtFacturatie from '../Facturatie/Overview';
import Filters from "../../components/Overview/Filters";
import { format, parseISO  } from 'date-fns'
import * as Auth from '../../components/Auth/Auth';

class WerkbonLogging extends React.Component {
    
    constructor(props){
        
        super(props);

        this.state = {
            title: props.title,
            type: props.type,
            data: [],
            columns: [
                        {name: 'date', label: 'Datum'},
                        {name: 'method', label: 'Methode'},
                        {name: 'username', label: 'Gebruiker'},
                        {name: 'postdata', label: 'Ingevulde data'},
                        {name: 'response', label: 'Response'}
                    ],
            werkbonId: props.werkbonId,
            page: 1,
            limit: 10,
            offset: 0,
            loading: false,
            totalPages: 1,
            filters: [
                        {name: 'dateTime', label: 'Datum', value: [null, null], type: 'dateRange'},
                        {name: 'userId', label: 'Werknemer(s)', value: [], type: 'multiselect', selectoptions: Auth.getWerknemers(), filterType: 'like'}
                    ]
        };  
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.type !== this.props.type) {
            this.setState({ type: this.props.type });
        }

        if (prevProps.werkbonId !== this.props.werkbonId) {
            this.setState({ werkbonId: this.props.werkbonId });
        }
    }

    handleOverviewFilter = (filters, page, type) => {
        
        let mappedFilters = [];
        let filter;

        filters.map((item, key) => {

            switch(item.type){

                default:
                    if(item.value){
                        filter = item.name+'='+item.value;
                        mappedFilters.push(filter);
                    }
                break;
            }
        });

        this.setState({filters: mappedFilters, page: page});
        this.getLogging(mappedFilters, page);
    }

    getLogging = (filters, page, limit) => {

        const self = this;

        const joinedFilters = filters.join('&');
        let url;
        limit = (limit) ? limit : this.state.limit;

        if(filters){
            url = '/Werkbonnen/'+this.state.werkbonId+'/Logging?'+joinedFilters+'&page='+page+'&limit='+limit
        }else{
            url = '/Werkbonnen/'+this.state.werkbonId+'/Logging?page='+page+'&limit='+limit;
        }

        self.setState({loading: true});

        Request.Call(url, 'get', null, function(response){

            let data = [];

            response.data.data.map((val) => {

                let datum = (val.datetime) ? format(parseISO(val.datetime), 'dd-MM-yyyy HH:mm:ss') : null;

                if(val.response.includes('bijgewerkt') || val.response.includes('Niet alle velden zijn gevuld')){
                    val.method = 'Werkbon bijgewerkt';
                }else{
                    val.method = 'Werkbon aangemaakt';
                }
               
                data.push({date: datum, username: val.username, method: val.method, postdata: val.postdata, response: val.response});
                return false;
            });

            const totalPages = Math.ceil(response.data.total/self.state.limit);
            self.setState({data: data, total: response.data.total, totalPages: totalPages, loading: false});
        });
    }

    prevNextPage = (prevNext) => {

        let page;

        if(prevNext === 'prev'){
            if(this.state.page === 1){
                return;
            }
            page = (this.state.page-1);
        }else{
            if((this.state.limit * this.state.page) >= this.state.total){
                return;
            }

            page = (this.state.page+1);
        }

        console.log(page);
       
        this.setState({ page: page });
        this.getLogging(this.state.filters, page);
    }

    changeLimit = (limit) => {
        this.setState({limit: limit});
        this.getLogging(this.state.filters, 1, limit);
    }

    changePage = (page) => {
        this.setState({page: page});
        this.getLogging(this.state.filters, page, this.state.limit);
    }

    render(){
        return (
            <div className="werkbonHistory">   
                <div className="overviewHeader">
                    <div className="title">
                        <Title title="Historie" size="4"></Title>
                    </div>
                    <Filters name="facturatie-werkbonnen" onChange={this.handleOverviewFilter} filterType="werkbonnen" filters={this.state.filters}></Filters>
                </div>
                <OverzichtFacturatie showColumns={true} data={this.state.data} columns={this.state.columns} loading={this.state.loading} prevPage={() => this.prevNextPage('prev')} nextPage={() => this.prevNextPage('next')} totalPages={this.state.totalPages} changeLimit={(e) => this.changeLimit(e.target.value)} currentPage={this.state.page} changePage={(e) => this.changePage(e.target.value)}></OverzichtFacturatie>
            </div>
        )
    }

}
  
export default WerkbonLogging