import React from 'react';
import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import Filters from "../../components/Overview/Filters"
import * as Request from "../../components/Call";
import MobileOVerview from "../../components/Overview/Mobile"
import history from '../../components/history';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Materiaal extends React.Component {

    constructor(props){
        super(props);  
        
        this.state = {
            filters: this.getFilters(),
            materiaal: [],
            page: 1,
            limit: 20,
            loading: false
        }
    }

    componentDidMount() { 
        this.handleFilter(this.state.filters)
    }
      
    getFilters = () => {
        return [
            {name: 'actief', label: 'Alles tonen', values: [true, false], value: true, type: 'checkbox', valueType: {type: 'both', ifValueIs: true}},
            {name: 'searchField', label: 'Materiaal', value: '', type: 'text', filterType: 'like'}
        ]
    }

    handleFilter = (filters, page, force = true) => {

        let mappedFilters = [];
        let filter;

        filters.map((item, key) => {

            switch(item.type){

                default:
                    if(item.value){
                        filter = item.name+'='+item.value;
                        mappedFilters.push(filter);
                    }
                break;

                case 'checkbox':
                    if(item.valueType){
                        if(item.valueType.type === 'both'){
                            if(item.value === item.valueType.ifValueIs){
                                item.selectedValue = item.values.join(',');     
                            }else{
                                item.selectedValue = (!item.value);
                            }       
                        }
                    }
        
                    filter = item.name+'='+item.selectedValue;
                    mappedFilters.push(filter);
                break;

            }
            
        });

        if(page){
            this.getMateriaal(mappedFilters.join('&'), page, force);
        }else{
            this.getMateriaal(mappedFilters.join('&'), this.state.page, force);
        }
    }

    getMateriaal = (filters, page, force) => {

        if(typeof filters === 'object'){
            this.handleFilter(filters, page, false)
        }

        const self = this;
        
        if(this.state.loading) return;
        this.setState({loading: true});

        let pageNumber = (page) ? page : this.state.page;

        Request.Call('/Materiaal/Overview?'+filters+'&page='+pageNumber+'&limit='+this.state.limit, 'get', null, function(response){
            
            let data = [];

            response.data.map((val) => {

                let title;

                if(!val.artikelnr){
                    title = val.omschrijving;
                }else{
                    title = val.artikelnr + ' ' + val.omschrijving;
                }
               
                data.push({id: val.id, title: title });
                return false;
            });            

            if(page === self.state.page || force){
                self.setState({ materiaal: [...data], loading: false, page: page});
            }else{
                self.setState({ materiaal: [...self.state.materiaal, ...data], loading: false, page: page});
            }
            
        });
    }

    rowClick = (id) => {
        history.push('/Materiaal/' + id)
    }

    RedirectAddMateriaal = () => {
        history.push('/Materiaal/Nieuw')
    }

    render(){
        return (
            <div className="overzicht">
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>

                <div className="pageTitle">
                <Title title="Materiaal" size="3" ></Title>
                    <FontAwesomeIcon className="plusIcon" icon="plus" size="lg" onClick={this.RedirectAddMateriaal}/>
                </div>
                <MobileOVerview name="materiaal" loading={this.state.loading} pageLoad={this.getMateriaal} limit={this.state.limit} page={this.state.page} data={this.state.materiaal} rowClick={this.rowClick} handleFilter={this.handleFilter} filters={this.state.filters}></MobileOVerview>
            </div>
        )
    }
}

export default Materiaal