import React from 'react';
import history from '../history';

const scrollToTopExclusion = ["/Werkbonnen"];

history.listen(
    (e) => {
        if(scrollToTopExclusion.indexOf(e.pathname) < 0){
            scrollTop();
        }
    }
)

const scrollTop = () => {
    window.scrollTo(0, 0);
}

const eventListenerScroll = () => {
    document.addEventListener("scroll", (event) => {
        saveScrollLocationPerPage(history.location.pathname, event.target.documentElement.scrollTop)
    });
}

const saveScrollLocationPerPage = (page, offset) => {
    
    const scrollHistory = (localStorage.getItem("scrollHistory")) ? JSON.parse(localStorage.getItem("scrollHistory")) : {};

    if(scrollHistory[page]){
        scrollHistory[page]['scrollOffset'] =  offset;
    }else{
        scrollHistory[page] = {scrollOffset: offset};
    }

    localStorage.setItem('scrollHistory', JSON.stringify(scrollHistory));
}

export const goBackToScrollPositionByPage = (page) => {

    let scrollHistory = localStorage.getItem("scrollHistory");
    scrollHistory = JSON.parse(scrollHistory);

    console.log(scrollHistory)
    if(scrollHistory[page]){
        setTimeout(() => { 
            window.scrollTo(0, scrollHistory[page]['scrollOffset']);
        },100 )
    }
}

export const handleScroll = () => {
    eventListenerScroll();
}

export default handleScroll;