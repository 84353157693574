import React from "react";
import { Grid } from "@mui/material";
import { format } from 'date-fns'
import history from '../../components/history';
import Title from "../Title";
import formatDate from "./formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDialog from "../Dialog";
import { AuthUser } from "../Auth/Auth";
import { nl } from "date-fns/locale";

class MobileCalendar extends React.Component {

    constructor(props){

        super(props);

        let startTime = (props.startTime) ? props.startTime : 7;
        let endTime = (props.endTime) ? props.endTime : 21;
        let times = this.createTimes(startTime, endTime);

        this.state = {
            calendarItems: props.data,
            endDate: props.endDate,
            times: times,
            addAfspraak: props.addAfspraak,
            date: (props.startDate) ? props.startDate : format(new Date(), 'yyyy-MM-dd'),
            showDialog: false,
            dialogContent: '',
            collegas: []
        }        

        this.currentTime = React.createRef();
    }

    getDifference = (prevProps, type) => {

        const results = prevProps.data.filter(({ id: id1 }) => !this.props.data.some(({ id: id2 }) => id2 === id1));

        if(results.length > 0 && type == 'data'){
            return true;
        }

        if(prevProps.data.length !== this.props.data.length && type == 'data'){
            return true;
        }

        if(prevProps.startDate != this.props.startDate && type == 'date'){
            return true;
        }

        return false;
    }

    componentWillUpdate = (prevProps, prevState) => {

        if(this.getDifference(prevProps, 'data')){
            this.setState({calendarItems: prevProps.data});
        }

        if(this.getDifference(prevProps, 'date')){ 
            this.setState({date: prevProps.startDate, showDialog: false});
        }
    }

    componentDidMount = (e) => {
        this.scrollToTime();
    }

    scrollToTime = () => {
        
        if(!this.currentTime.current) return;

        window.scrollTo({top: this.currentTime.current.offsetTop, left: 0, behavior: 'smooth'});
    }

    createTimes = (startTime, endTime) => {

        let times = [];

        for(startTime; startTime <= endTime; startTime++){
            if(startTime < 10){
                startTime = '0' + startTime;
            }
            let time = startTime + ':00';
            times.push(time);
        }

        return times;
    }

    getAfsprakenByTime = (time) => {

        const byTime = this.state.calendarItems.filter((item) => {
            item.startTime = (item.startTime) ? item.startTime : '07:00';
            return item.startTime.split(':')[0] === time.split(':')[0];
          });


        return byTime;
    }

    openAfspraak = (event, id) => {
        if(event.target.nodeName.toLowerCase() === 'div'){
            history.push('/Planning/' + id);
        }
    }

    navigateMaps = (mapsUrl) => {
        window.open(mapsUrl);
    }

    openUsers = (users) => {
        this.setState({collegas: users, showDialog: true});
    }

    showCollegas = () => {
        return (
            <p className="collegas">
                {
                    this.state.collegas.map((collega) => {
                        return (<p>{collega}</p>)
                    })
                }
            </p>
        );
    }

    callCustomer = (number) => {
        window.open('tel:' + number);
    }

    getDayNameByDate = () => {
        const dayName = format(new Date(this.state.date), 'EEEE', { locale: nl });
        return dayName.charAt(0).toUpperCase() + dayName.slice(1)
    }

    openTask = (id) => {
        history.push("/Taken/" + id);
    }

    render(){

        let currentTime = format(new Date(), 'HH:00');

        return (
            <div className="calendar">
                <CustomDialog open={this.state.showDialog} title="Aanwezige collega's" content={this.showCollegas()} buttons={[{title: 'Sluiten'}]}/>
                <Title title={this.getDayNameByDate()+ ' - ' + formatDate(this.state.date)} size="4"></Title>
                <Grid container spacing={2}>
                    {
                        this.state.times.map((time, key) => {

                            let ref = (currentTime === time) && this.currentTime;

                            return  (<>
                                <Grid className="time" item xs={2} sm={2} md={1}>
                                    {time} 
                                </Grid>
                                <Grid className="item" ref={ref} item xs={10} sm={10} md={11}>
                                    {
                                        this.getAfsprakenByTime(time).map((item, key) => {
                                            return (
                                                <Grid className="calendarItem" container data-selected={item.selected} item xs={12} sm={11} md={6} lg={5} onClick={(e) => {this.openAfspraak(e, item.id)}}>
                                                    
                                                    <Grid container xs={10} spacing={1}>
                                                        <Grid item xs={12}>
                                                            {item.startTime} - {item.endTime}
                                                            {
                                                            item.callCustomer &&
                                                                <FontAwesomeIcon icon="phone" size="lg" onClick={(e) => this.callCustomer(item.callCustomer)} className="mt-05 ml-05" />
                                                        }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            {item.title}
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            {item.subTitle} <br /><br />
                                                            {item.opdrachtgever}
                                                        </Grid>
                                                    </Grid>
                                                    <Grid xs={2} container direction="column" alignItems="center" justifyContent="center">
                                                        {
                                                            item.maps &&
                                                                <Grid item>
                                                                    <FontAwesomeIcon icon="map-marked-alt" size="2x" className="pointer mt-05" onClick={(e) => this.navigateMaps(item.maps)}/>
                                                                </Grid>
                                                        }

                                                        {
                                                            item.hasTask > 0 &&
                                                            <Grid item>
                                                                    <FontAwesomeIcon icon="tasks" size="2x" className="pointer mt-05" onClick={(e) => this.openTask(item.hasTask)}/>    
                                                                </Grid>
                                                        }
                                                        {
                                                            item.gebruikers.length > 0 &&
                                                                <Grid item>
                                                                    <FontAwesomeIcon icon="users" size="2x" className="pointer mt-05" onClick={(e) => this.openUsers(item.gebruikers)}/>
                                                                </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>

                                            )
                                        })
                                    }
                                    {
                                        AuthUser('editPlanning') && 
                                            <div className="newItem" onClick={(e) => {this.state.addAfspraak(time)}}>
                                                Nieuwe afspraak toevoegen
                                            </div>
                                    }
                                    
                                </Grid>
                            </>)
                        })
                    }
                </Grid>
            </div>
        )
    }
}

export default MobileCalendar;