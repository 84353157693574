import React from "react";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

class CustomDialog extends React.Component {

    constructor(props){
        
        super(props);

        const defaultBtns = [{title: 'Sluiten', onClick: this.closeDialog }];
        
        this.state = {
            open:  (props.open) ? props.open : false,
            title: props.title,
            content: props.content,
            buttons: (props.buttons) ? props.buttons : defaultBtns
        };
    }

    componentWillReceiveProps = (prevProps) => {
        
        if (prevProps.open !== this.state.open) {
            this.setState({open: prevProps.open});
        }

        if(prevProps.content !== this.state.content){
            this.setState({content: prevProps.content});
        }
    }

    closeDialog = () => {
        this.setState({open: false});
    }

    handleClick = (clickFunction) => {
        this.setState({open: false});
        clickFunction();
    }

    render(){
        return (
            <>
                <Dialog
                    fullWidth
                    maxWidth="xs"
                    open={this.state.open}
                    onClose={this.closeDialog}
                >
                    <DialogTitle>
                        {this.state.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText className="dialogContent">
                            {this.state.content}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {
                            this.state.buttons.map((btn, key) => {
                                return  btn.onClick ?
                                    <Button key={key} onClick={(e) => this.handleClick(btn.onClick)}>{btn.title}</Button>
                                :
                                <Button key={key} onClick={this.closeDialog}>{btn.title}</Button>
                            })
                        }
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}
export default CustomDialog