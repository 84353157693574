import React from 'react';
import { TextField, Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import Box from '@mui/material/Box';
import { nl } from "date-fns/locale";
import Button from '@mui/material/Button';
import { format } from 'date-fns'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';

class Filters extends React.Component {

    constructor(props){

        super(props);  

        let filters = props.filters;

        if(sessionStorage.getItem('filters-' + props.name)){
            filters = JSON.parse(sessionStorage.getItem('filters-' + props.name));   
        }

        this.state = {
            filters: filters,
            onChange: props.onChange,
            name: props.name, 
            open: false,
            filterType: (props.filterType) ? props.filterType : false
        }

        if(props.filterType){
            props.onChange(filters, 1, props.filterType);
        }else{
            props.onChange(filters, 1);
        }
    }

    handleChange = (e) => {
       
        let filters = this.state.filters;

        switch (e.target.type)
        {
            default:
                filters.map((item, key) => {
                    if(item.name === e.target.name){
                        filters[key]['value'] = e.target.value;
                    }
                })
            break;

            case 'checkbox':

                filters.map((item, key) => {
                    if(item.name === e.target.name){

                        if(item.valueType){
                            if(item.valueType.type === 'both'){
                                if(item.value === item.valueType.ifValueIs){
                                    filters[key]['selectedValue'] = item.values.join(',');
                                }
                                    
                                filters[key]['value'] = (!item.value);
                            }
                        }else{
                            filters[key]['value'] = (!item.value);
                        }
                    }
                });
                
                
            break;
        }

        this.setState({filters: filters});
    }

    handleChangeSelect = (e) => {
        const filters = this.state.filters;
        const name = e.target.name;
        const value = e.target.value;

        filters.map((item, key) => {

            if(item.name === name){
                filters[key]['value'] = value;
            }
        });

        this.setState({filters: filters});
    }

    isSelected = (itemValue, selectedValue) => {

        if(!itemValue) return false;    

        return (itemValue.indexOf(selectedValue) > -1);
    }

    getSelectedLabels = (item, values) => {
        let selectedLabels = [];

        values.map((selectedId) => {
            const selectedItem = item['selectoptions'].find(o => o.value === selectedId);
            if(item['selectoptions'] && selectedItem){
                selectedLabels.push(selectedItem['label']);
            }
        });

        return selectedLabels.join(', ');
    }

    openCloseFilters = (e) => {
        this.setState({open: (!this.state.open)});
    }

    changeRange = (value, name) => {

        const filters = this.state.filters;

        filters.map((item, key) => {

            if(item.name === name){
                
                const formattedDates = [];
                if(!value) return true;

                value.map((datevalue) => {

                    if(datevalue){
                        
                        let newDate;
                        if((new Date(datevalue) !== "Invalid Date") && !isNaN(new Date(datevalue))){
                            newDate = format(new Date(datevalue), 'yyyy-MM-dd');
                        }
                        
                        formattedDates.push(newDate);
                        return true;
                    }

                    formattedDates.push(datevalue);
                });

                filters[key]['value'] = formattedDates;
            }
        });
        
        this.setState({filters: filters});        
    }

    filter = (e) => {
        sessionStorage.setItem('filters-' + this.state.name, JSON.stringify(this.state.filters));
        if(this.state.filterType){
            this.state.onChange(this.state.filters, 1, this.state.filterType)
        }else{
            this.state.onChange(this.state.filters, 1)
        }
        
        this.openCloseFilters();
    }

    emptyValues = (filters, key) => {

        switch(filters[key]['type']){
    
            default:
                if(Array.isArray(filters[key]['value'])){
                    filters[key]['value'] = [];
                }else{
                    filters[key]['value'] = '';
                }
            break;

            case "dateRange":
                filters[key]['value'] = [null, null];
            break;
        }

        return filters;
    }

    resetFilters = (key) => {
        let filters = this.state.filters;

        if(key !== undefined){
            filters = this.emptyValues(filters, key);
        }else{

            filters.map((filter, key) => {
                filters = this.emptyValues(filters, key);            
            });
        }

        sessionStorage.removeItem('filters-' + this.state.name);
        sessionStorage.setItem('filters-' + this.state.name, JSON.stringify(this.state.filters));
        if(this.state.filterType){
            this.state.onChange(this.state.filters, 1, this.state.filterType)
        }else{
            this.state.onChange(this.state.filters, 1)
        }
    }

    render(){
        return (
            <div className="filters">   
                <FontAwesomeIcon className="filterIcon" icon="search" size="lg" onClick={this.openCloseFilters}/>
                {
                    this.state.open && 
                    <div className="overviewFilters">
                        {

                            this.state.filters.map((item, key) => {

                                switch(item.type){
                                    default:
                                        
                                        return <TextField
                                        margin="normal"
                                        name={item.name}
                                        label={item.label}
                                        type="text"
                                        key={key}
                                        value={item.value}
                                        onChange={(e) => this.handleChange(e)}
                                    />
                                    break;

                                    case 'checkbox':
                                        return <FormControlLabel
                                        key={key}
                                        name={item.name}
                                        control={<Checkbox name={item.name} onChange={(e) => this.handleChange(e)} checked={item.value}/>}
                                        label={item.label}
                                        labelPlacement="end"
                                    />
                                    break;

                                    case 'multiselect':
                                        return <FormControl key={key} sx={{ width: 300 }} margin="normal">
                                                <InputLabel id="demo-multiple-checkbox-label">{item.label}</InputLabel>
                                                <Select
                                                    name={item.name}
                                                    labelId="demo-multiple-checkbox-label"
                                                    multiple
                                                    value={item.value}
                                                    onChange={(e) =>  this.handleChangeSelect(e)}
                                                    input={<OutlinedInput label="Tag" />}
                                                    renderValue={
                                                        (selected) => this.getSelectedLabels(item, selected)}
                                                >
                                                {item.selectoptions.map((option, key) => (
                                                    
                                                    <MenuItem key={key} value={option.value}>
                                                        <Checkbox checked={this.isSelected(item.value, option.value)} />
                                                        <ListItemText primary={option.label} />
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                      </FormControl>
                                        
                                    break;

                                    case 'dateRange':
                                        return <LocalizationProvider key={key} locale={nl} dateAdapter={AdapterDateFns}>
                                        <DateRangePicker
                                          startText="Vanaf"
                                          endText="Tot en met"
                                          value={item.value}
                                          cancelText="Annuleren"
                                          clearable={true}
                                          clearText="Legen"
                                          toolbarTitle="Selecteer een datumbereik"
                                          mask="__-__-____"
                                          onChange={(value) => this.changeRange(value, item.name)}
                                          PopperProps={{
                                            placement: "bottom-start",
                                          }}
                                          renderInput={(startProps, endProps) => (
                                            <React.Fragment variant='contained'>
                                              <TextField sx={{ my: 2, width: 300 }} {...startProps} />
                                              <Box sx={{ mx: 2 }}> t/m  </Box>
                                              <TextField sx={{ my: 2, width: 300 }} {...endProps} />
                                            </React.Fragment>
                                          )}
                                        />
                                      </LocalizationProvider>
                                    break;
                                }
                            })
                        }
                        <div className="buttons">
                            <Button className="button" onClick={(e) => this.filter(e)} variant="contained">Zoeken</Button>
                            <Button className="button" onClick={(e) => this.resetFilters()} variant="outlined">Reset</Button>
                        </div>
                    </div>
                }
                {
                    (this.state.open === false) && 
                        this.state.filters.map((item, key) => {

                            if(Array.isArray(item.value) && item.type === 'dateRange'){

                                let hasItems = false;
                                
                                item.value.map((values) => {
                                    if(values){
                                        hasItems = true;
                                    }
                                })

                                if(hasItems){
                                    
                                    return <label key={key} className="filtered" onClick={(e) => this.resetFilters(key)}><FontAwesomeIcon className="removeIcon" icon="times" size="lg" /> {item.label}:{
                                        item.value.map((filtVal, subKey) => {
                                            if(filtVal && (new Date(filtVal) !== "Invalid Date") && !isNaN(new Date(filtVal))){
                                                let nextVal = item.value[(subKey+1)];
                                                if(nextVal && (new Date(nextVal) !== "Invalid Date") && !isNaN(new Date(nextVal))){
                                                    return format(new Date(filtVal), 'dd-MM-yyyy') + ' - ';
                                                }else{
                                                    return format(new Date(filtVal), 'dd-MM-yyyy');
                                                }
                                                
                                            }
                                        })
                                        
                                    }</label>    
                                }
                            }else if(item.type === 'multiselect'){
                                if(item.value.length > 0){
                                    return <label key={key} className="filtered" onClick={(e) => this.resetFilters(key)}><FontAwesomeIcon className="removeIcon" icon="times" size="lg" /> {item.label}: {this.getSelectedLabels(item, item.value)}</label>
                                }
                            }else if(item.value){

                                if(item.value === true){
                                    item.value = 'Ja';
                                }else if(item.value === false){
                                    item.value = 'Nee';
                                }

                                return <label key={key} className="filtered" onClick={(e) => this.resetFilters(key)}><FontAwesomeIcon className="removeIcon" icon="times" size="lg" /> {item.label}: {item.value}</label>
                            }
                        })
                }
            </div>
        )
    }
}

export default Filters