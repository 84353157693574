import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import MobileOVerview from "../../components/Overview/Mobile"
import history from '../../components/history';
import React from "react";
import * as Request from "../../components/Call";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Gebruikers extends React.Component {

    constructor(props){
        
        super(props);

        this.state = {
            users: [],
        };         
    }

    rowClick = (id) => {
        history.push('/Gebruikers/' + id)
    }

    getGebruikers = () => {

        const self = this;

        Request.Call('/Gebruikers/Overview', 'get', null, function(response){
            
            let data = [];

            response.data.map((val) => {
               
                data.push({id: val.id, title: val.firstname + ' ' + val.lastname });
                return false;
            });

            self.setState({users: data});
        });
    }

    componentDidMount = () => {
        this.getGebruikers();
    }

    RedirectGebruiker = () => {
        history.push('/Gebruikers/Nieuw')
    }

    render(){
        return (
            <div className="overzicht">   
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>

                <div className="pageTitle">
                    <Title title="Gebruikers" size="3"></Title>
                    <FontAwesomeIcon className="plusIcon" icon="plus" size="lg" onClick={this.RedirectGebruiker}/>
                </div>

                <MobileOVerview data={this.state.users} rowClick={this.rowClick}></MobileOVerview>

            </div>
        )
    }
}
export default Gebruikers