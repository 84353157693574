import { icon } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import React from 'react';

class OverzichtFacturatie extends React.Component {

    constructor(props){
        
        super(props);

        this.state = {
            data: props.data,
            icons: props.icons,
            columns: props.columns,
            handleRowClick: props.handleRowClick,
            draggAble: (props.draggAble) ? props.draggAble : false,
            dragDrop: (props.dragDrop) ? props.dragDrop : false,
            rowClick: (props.rowClick) ? props.rowClick : false,
            dragStarted: false,
            loading: (props.loading) ? props.loading : false,
            nextPage: (props.nextPage) ? props.nextPage : false,
            prevPage: (props.prevPage) ? props.prevPage : false,
            totalPages: (props.totalPages) ? props.totalPages : 0,
            currentPage: (props.currentPage) ? props.currentPage : 1,
            changeLimit: (props.changeLimit) ? props.changeLimit : false,
            changePage: (props.changePage) ? props.changePage : false,
            showColumns: (props.showColumns) ? props.showColumns : false,
        };
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.data !== this.props.data) {
            this.setState({ data: this.props.data });
        }

        if (prevProps.icons !== this.props.icons) {
            this.setState({ icons: this.props.icons });
        }

        if(prevProps.loading !== this.state.loading){
            this.setState({loading: prevProps.loading});
        }

        if(prevProps.totalPages !== this.state.totalPages){
            this.setState({totalPages: prevProps.totalPages});
        }

        if(prevProps.currentPage !== this.state.currentPage){
            this.setState({currentPage: prevProps.currentPage});
        }    
        
        if(prevProps.totalPages !== this.state.totalPages){
            this.setState({totalPages: prevProps.totalPages});
        }  
    }

    checkForSubTable = (data, rowIndex) => {

        if(!data.subTable || !data.subTable.show) return;

        return data.subTable.data.map((subTableData, subTableRowIndex) => {
            return this.createSubTableRows(subTableData, subTableRowIndex, data.subTable, rowIndex, data['id'])
        });

    }

    createSubTableRows = (data, rowIndex, subTable, parentIndex, parentId) => {

        return <tr className='subTableRow' key={rowIndex}>
                <td key={rowIndex+'d'}></td>
                { this.createSubTableData(data, subTable.columns) }

                {
                    (data['id'] != parentId) ?
                    <td key={rowIndex+'f'}>{this.createSubTableEditIcon(rowIndex, parentIndex, subTable.editWerkbon)}&nbsp;&nbsp;&nbsp;{this.createSubTableDeleteIcon(rowIndex, parentIndex, subTable.deleteWerkbon)}</td>
                    :
                    <td>{this.createSubTableEditIcon(rowIndex, parentIndex, subTable.editWerkbon)}</td>
                }
            </tr>
    }

    createSubTableData = (data, columns) => {
        return columns.map((column, key) => {
            return <td key={key+'e'}>{data[column.name]}</td>;
        });
    }

    createSubTableEditIcon = (rowIndex, parentIndex, action) => {
        return <FontAwesomeIcon size='1x' onClick={() => { action(rowIndex, parentIndex) }} icon='pencil-alt' />;
    }

    createSubTableDeleteIcon = (rowIndex, parentIndex, action) => {
        return <FontAwesomeIcon size='1x' onClick={() => { action(rowIndex, parentIndex) }} icon='trash' />;
    }

    createTableRows = (data, rowIndex) => {
        let tableRows = [];

        if(this.state.icons){
            tableRows.push(this.createIcons(false, data.collapseExpandIcon, rowIndex));
            tableRows.push(this.createIcons('before', false, rowIndex));
        }

        tableRows.push(this.createTableData(data, rowIndex));
        
        if(this.state.icons){
            tableRows.push(this.createIcons('after', false, rowIndex));
        }
        return <tr key={rowIndex}>{ tableRows }</tr>
    }

    createTableData = (data, rowIndex) => {
        return Object.keys(data).map((column, objectKey) => {
            if(this.state.columns.find(c => c.name === column && c.display !== false)){
                if(this.state.rowClick){
                    return <td className="clickable" onClick={(e) => {this.state.rowClick(data['id'])}} key={objectKey}>{data[column]}</td>            
                }else{
                    return <td key={objectKey}>{data[column]}</td>            
                }
            }
        });
    }

    createIcons = (type = false, customIcon = false, rowIndex = false) => {

        const icons = this.state.icons.filter(i => i.position === type);

        if(type !== false){
            return icons.map((icon, iconKey) => {
                if(icon.onDragStart){
                    return <td key={(rowIndex+iconKey+'a')} draggable onDragStart={(e) => { this.onDragStart(e, rowIndex) }} className="draggable"><FontAwesomeIcon size={(icon.size) ? icon.size : '1x'} icon={icon.name} /></td>
                }else{
                    return <td key={(rowIndex+iconKey+'b')}><FontAwesomeIcon size={(icon.size) ? icon.size : '1x'} onClick={() => { icon.action(rowIndex) }}  icon={icon.name} /></td>
                }
            }); 
        }else if(customIcon !== false){
            const icon = this.state.columns.find(c => c.name === 'collapseExpandIcon');
            return <td key={(rowIndex+'c')}><FontAwesomeIcon size={(icon.size) ? icon.size : '1x'} onClick={() => { icon.action(rowIndex) }}  icon={customIcon} /></td>
        }          
    }

    onDragStart = (e, rowIndex) => {
        e.dataTransfer.setData("rowIndex", rowIndex);
        this.setState({dragStarted: true});
    }


    onDragOver = (e) => {

        e.preventDefault();
        e.stopPropagation();
        
        if(e.target.parentNode.parentNode.classList.contains('droppable')){
            e.target.parentNode.parentNode.style.color = 'var(--color-blue)';
            e.target.parentNode.parentNode.style.fontWeight = 'bold';
        }
    }

    onDrop = (e, parentRowIndex) => {
        const draggedRowIndex = parseInt(e.dataTransfer.getData("rowIndex"));

        this.state.dragDrop(draggedRowIndex, parentRowIndex);
        this.removeStyle(e.target.parentNode.parentNode);
    }
    

    onDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if(e.target.parentNode.parentNode.classList.contains('droppable')){
            this.removeStyle(e.target.parentNode.parentNode);
        }
    }

    removeStyle = (target) => {
        target.style.color = 'black';
        target.style.fontWeight = 'normal';
    }

    renderTableHeaders = () => {

        const tableHeaders = [];

        if(this.state.data.length > 0){
            Object.keys(this.state.data[0]).map((columnName, rowIndex) => {

                let columDetails = this.state.columns.find((col) => col.name === columnName);

                if(columDetails){
                    tableHeaders.push(<th>{columDetails.label}</th>)
                }
            });
        }
        
        return <tr key="01">{ tableHeaders }</tr>
    }

    renderTable = () => {

        return this.state.data.map((data, rowIndex) => {

            let tableRow = [];

            if(this.state.dragDrop){
                tableRow.push(<thead key={rowIndex+'g'} className='droppable' onDragOver={(e) => {this.onDragOver(e)}} onDragLeave={(e) => {this.onDragLeave(e)}} onDrop={(e) => {this.onDrop(e, rowIndex)}}>{this.createTableRows(data, rowIndex)}</thead>)
            }else{
                tableRow.push(this.createTableRows(data, rowIndex))
            }
            
            if(data.subTable && data.subTable.show){
                tableRow.push(this.checkForSubTable(data, rowIndex));
            }

            return tableRow;
        });
    }

    renderPageSelectOptions = () => {
        
        const options = []

        for(let i = 1; i <= this.state.totalPages; i++){

            if(this.state.currentPage === i){
                options.push(<option value={i} selected>{i}</option>)
            }else{
                options.push(<option value={i}>{i}</option>)
            }            
        }

        return options;
    }

    render(){

        return (
            <div className="Overview">
                {
                    this.state.loading &&
                        <FontAwesomeIcon className="spinner" icon="spinner" size="2x" spin />
                }
                <div  className='parentTable'>
                    <table>
                        <tbody>

                            {
                                (this.state.showColumns) && this.renderTableHeaders()
                            }

                            { 
                                this.renderTable()
                            }
                        </tbody>
                    </table>
                </div>
                <p className='pagination'>
                    <label className='aantal'>aantal <select onChange={this.state.changeLimit}><option>10</option><option>20</option><option>30</option><option>40</option><option>50</option><option>100</option></select></label>
                        <label>pagina <select onChange={this.state.changePage}>{this.renderPageSelectOptions()}</select> van de {this.state.totalPages} 
                        <label className='lt' onClick={this.state.prevPage}><FontAwesomeIcon icon="angle-left" size="1x" /></label> 
                        <label className='gt' onClick={this.state.nextPage}><FontAwesomeIcon icon="angle-right" size="1x" /></label>
                    </label>
                </p>
            </div>
        )
    }

}

export default OverzichtFacturatie;