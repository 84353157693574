import axios from 'axios';
import Cookies from 'js-cookie';
import settings from '../config';
import { toast } from 'react-toastify';


const headers = (hasFiles) => {

    let headers = {};

    if(hasFiles){
        headers['content-type'] = 'multipart/form-data';
    }else{
        headers['content-type'] = 'application/json';
    }
    
    if(Cookies.get('authToken')){
        headers.Authorization = `Bearer ${Cookies.get('authToken')}`
    }

    return headers;
}

export const Call = (url, method, body, callback, files = []) => {
    url = settings.apiUrl + url;
    const realMethod = method;
    method = (method === 'file') ? "post" : method;
    
    let header = headers();

    if(files.length > 0){
        let formData = new FormData();
        header = headers(true);

        files.forEach((file, key) => {
            formData.append('file' + key, file);
        });

        formData.append('body', JSON.stringify(body));

        body = formData;
    }

    axios({
        method: method,
        url: url,
        headers: header,
        responseType: (realMethod === 'file') ? 'blob' : 'json',
        data: (body) && body,
    })
    .then(response => {
        callback(response)
    })
    .catch((error) => {
        if(error.response){
            switch(error.response.status){
                case 401:
                    Cookies.remove('authToken');
                    window.location.href = '/';
                break;

                default:

                    if(error.response.data && error.response.data.msg){
                        toast.error(error.response.data.msg, {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }else{
                        toast.error("Er is iets mis gegaan.", {
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            progress: undefined,
                            theme: "light",
                        });
                    }
                    callback(error.response)
                break;
            }
        }
    });
}