import React from "react";
import Breadcrumb from "../../../components/Menu/Breadcrumb"
import Title from "../../../components/Title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Auth from '../../../components/Auth/Auth';
import history from '../../../components/history';
import MobileOVerview from "../../../components/Overview/Mobile"
import * as Request from "../../../components/Call";
import { format, parseISO  } from 'date-fns'
import { toast } from 'react-toastify';

class ChecklistWoningen extends React.Component {

    constructor(props){
        
        super(props); 

        let filters = [
            {name: 'searchField', label: 'Adres', value: '', type: 'text', filterType: 'like'},
            {name: 'datum', label: 'Datum', value: [null, null], type: 'dateRange'}
        ];

        this.state = {
            checklistsWoningen: [],
            page: 1,
            limit: 20,
            loading: false,
            total: 10,
            filters: filters
        };   
    }

    RedirectChecklist = () => {
        history.push('/Checklist-woningen/Nieuw')
    }

    handleFilter = (filters, page, force = true) => {

        const mappedFilters = [];
        let filter;

        filters.map((item, key) => {

            switch(item.type){

                default:
                    if(item.value){
                        filter = item.name+'='+item.value;
                        mappedFilters.push(filter);
                    }
                break;
            }
        });

        if(page){
            this.getChecklistsWoningen(mappedFilters.join('&'), page, force);
        }else{
            this.getChecklistsWoningen(mappedFilters.join('&'), this.state.page, force);
        }
    }

    deleteChecklist = (id) => {

        const self = this;

        Request.Call('/Checklist/Woningen/' + id, 'delete', null, function(response){
            
            toast.success('Checklist is verwijderd.', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            self.getChecklistsWoningen(self.state.filters, 1)
        });
    }

    getChecklistsWoningen = (filters, page, force) => {

        const self = this;

        if(typeof filters === 'object'){
            this.handleFilter(filters, page, false)
        }

        if(this.state.loading) return;
        this.setState({loading: true});
       
        let pageNumber = (page) ? page : this.state.page;

        let url;

        if(filters){
            url = '/Checklist/Woningen/Overview?'+filters+'&page='+pageNumber+'&limit='+this.state.limit;
        }else{
            url = '/Checklist/Woningen/Overview?page='+pageNumber+'&limit='+this.state.limit;
        }
       
        Request.Call(url, 'get', null, function(response){
            
            let data = [];

            response.data.checklists.map((val) => {

                let subTitle;
                let datum = (val.datum) ? format(parseISO(val.datum), 'dd-MM-yyyy') : null;
                
                if(datum && val.firstname){
                    subTitle = datum + ' - ' + val.firstname + ' ' + val.lastname;
                }else{
                    subTitle = datum;
                }

                data.push({id: val.id, title: val.straat + ' ' + val.huisnummer, subTitle: subTitle, titleRight: '', titleRightIcon: {icon: 'file-pdf'}, titleRightIconSize: '2x'});

                return false;
            });

            if(page === self.state.page){
                self.setState({ checklistsWoningen: [...data], loading: false, page: pageNumber});
            }else{
                self.setState({ checklistsWoningen: [...self.state.checklistsWoningen, ...data], page: pageNumber, loading: false});
            }
        });
    }

    rowClick = (itemId) => {
        history.push('/Checklist-woningen/' + itemId);
    }

    downloadChecklist = (itemId) => {

        Request.Call('/Checklist/Woningen/Download/' + itemId, 'file', [], function(response){
            const url = URL.createObjectURL(new Blob([response.data]));
            const a = document.createElement('a');
            a.download = 'checklist-woning.pdf';
            a.href = url;
            a.target = '_self';
            a.click();
        });
    }

    render(){

        const delChecklist = (Auth.AuthUser('allChecklists')) ? this.deleteChecklist : false;

        return (
            <div className="overzicht">   
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    <Title title="Checklist woningen" size="3"></Title>
                    <FontAwesomeIcon className="plusIcon" icon="plus" size="lg" onClick={this.RedirectChecklist}/>
                </div>

                <MobileOVerview total={this.state.total} checkboxes="" filters={this.state.filters} name="checklistsWoningen" handleFilter={this.handleFilter} loading={this.state.loading} pageLoad={this.getChecklistsWoningen} deleteRow={delChecklist} limit={this.state.limit} page={this.state.page} data={this.state.checklistsWoningen} rowClick={this.rowClick} iconClick={this.downloadChecklist} type="checklist"></MobileOVerview>
            </div>
        )
    }
}

export default ChecklistWoningen