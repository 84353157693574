import React from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Stack from '@mui/material/Stack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 

class Upload extends React.Component {

    constructor(props){
        
        super(props);
        
        this.state = {
            label: props.label,
            name: props.name,
            multiple: true,
            uploadFiles: props.files,
            oldFiles: props.oldFiles,
            readonly: props.readonly,
            required: (props.required) ? props.required : false,
        };
    }

    componentWillUpdate = (prevProps, prevState) => {
        if(this.state.oldFiles.length !== prevProps.oldFiles.length){
            this.setState({oldFiles: prevProps.oldFiles});
        }
    }

    handleFile = (e) => {

        let existingFiles = this.state.uploadFiles;

        Array.from(e.target.files).forEach((file) => {
            file.new = true;
            existingFiles.push(file);
        });

        this.setState({ uploadFiles: existingFiles });
    }

    removeFile = (key) => {

        let existingFiles = this.state.uploadFiles;

        if(key > -1){
            existingFiles.splice(key, 1);
        }

        this.setState({ uploadFiles: existingFiles });
    }

    removeOldFile = (key) => {
        let oldFiles = this.state.oldFiles;
        oldFiles[key]['deleted'] = true;

        this.setState({oldFiles, oldFiles});
    }

    render(){
        
        const Input = styled('input')({
            display: 'none',
        });

        return (
            <>
                <Stack direction="row" alignItems="center" spacing={2}>
                { !this.state.readonly && 
            
                    <label className="upload">
                         
                        <Input accept="image/*" onChange={(e) => this.handleFile(e)} multiple={this.state.multiple} type="file" />
                        <IconButton color="primary" aria-label={this.state.label} component="span">
                        <PhotoCamera />
                        </IconButton>
                        {this.state.label}
                    </label>
                }
                </Stack>
                <div className="files-list">
                {
                    this.state.uploadFiles.map((uploadedFile, key) => {
                        return <div key={key} className="file">
                                <label>{uploadedFile.name}</label>
                                <FontAwesomeIcon className="deleteIcon" onClick={() => { this.removeFile(key) }} size="lg" icon="times" />
                            </div>
                    })
                }
                {
                    this.state.oldFiles.map((oldFile, key) => {
                        if(!oldFile.deleted){
                            return <div key={key} className="file">
                                    <label><a href={ "/api/public" + oldFile.path} target="_blank">{oldFile.name}</a></label>
                                    <FontAwesomeIcon className="deleteIcon" onClick={() => { this.removeOldFile(key) }} size="lg" icon="times" />
                                </div>
                        }
                    })
                }
                </div>
            </>
        );
    }
}
export default Upload;