import * as React from 'react';
import Tile from './Tile'
import * as Auth from '../../Auth/Auth';

class Tiles extends React.Component {
    
    constructor(props){
        
        super(props);

        this.state = {
            tiles: [
                {title: 'Planning', icon: 'calendar-alt', url: '/Planning'},
                {title: 'Werkbon', icon: 'file-invoice', url: '/Werkbonnen/Nieuw'},
                {title: 'Mijn werkbonnen', icon: 'list', url: '/Werkbonnen'},
                {title: 'Materiaal', icon: 'ruler-combined', url: '/Materiaal'},
                {title: 'Taken', icon: 'tasks', url: '/Taken'},
                {title: 'Loguit', icon: 'sign-out-alt', url: '/Logoff'},
            ]
        }

        if(Auth.AuthUser('facturatie')){
            this.state.tiles.splice(((this.state.tiles.length-1)), 0, {title: 'Facturatie overzicht', icon: 'receipt', url: '/Facturatie'});
        }

        if(Auth.AuthUser('checklist')){
            this.state.tiles.splice(((this.state.tiles.length-1)), 0, {title: 'Checklist woningen', icon: 'house-user', url: '/Checklist-woningen'});
        }

        if(Auth.AuthUser('users')){
            this.state.tiles.splice(((this.state.tiles.length-1)), 0, {title: 'Gebruikers', icon: 'user', url: '/Gebruikers'});
        }
    }

    render() {
        
        return ( 
            <div className="tiles-menu">
                {
                    this.state.tiles.map((tile, key) => {
                        return <Tile key={key} className="tile" title={tile.title} icon={tile.icon} url={tile.url}></Tile>
                    })
                }                
            </div>
        )
    }

}

export default Tiles