import React from "react";
import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import MobileOVerview from "../../components/Overview/Mobile"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Request from "../../components/Call";
import history from '../../components/history';
import { format, parseISO  } from 'date-fns'
import { Button } from "@mui/material";
import * as Auth from '../../components/Auth/Auth';
import { toast } from 'react-toastify';
import { goBackToScrollPositionByPage } from '../../components/Scroll/Scroll.js';

class Werkbonnen extends React.Component {

    constructor(props){
        
        super(props);

        let filters = [
            {name: 'searchField', label: 'Adres', value: '', type: 'text', filterType: 'like'},
            {name: 'datum', label: 'Datum', value: [null, null], type: 'dateRange',}
        ];

        let checkboxes = false;

        if(Auth.AuthUser('allWerkbonnen')){
            checkboxes = {items: [], key: 'id', check: this.checkBox};
            filters.splice(((filters.length-1)), 0, {name: 'userId', label: 'Werknemer(s)', value: [], type: 'multiselect', selectoptions: Auth.getWerknemers(), filterType: 'like'});
        }      
        
        const total = (Auth.AuthUser('allWerkbonnen')) ? {value: 0, icon: 'clock'} : false;

        this.state = {
            werkbonnen: [],
            page: 1,
            limit: 20,
            total: total,
            loading: false,
            filters: filters,
            overviewCheckboxes: checkboxes
        };       
    }

    getLocalData = () => {

    }

    getWerkbonnen = (filters, page, force) => {

        const self = this;

        if(typeof filters === 'object'){
            this.handleFilter(filters, page, false)
        }

        if(this.state.loading) return;
        this.setState({loading: true});
       
        let pageNumber = (page) ? page : this.state.page;
        let url;
        

        if(filters){
            url = '/Werkbonnen/Overview?'+filters+'&page='+pageNumber+'&limit='+this.state.limit;
        }else{
            url = '/Werkbonnen/Overview?page='+pageNumber+'&limit='+this.state.limit;
        }

        let offsetRow = localStorage.getItem("offsetRow");

        if(offsetRow > 0){

            let localData = localStorage.getItem("localWerkbonnen");
            if(localData && page === self.state.page){

                localData = JSON.parse(localData);

                self.setState({werkbonnen: [...localData.data], loading: false, page: localData.page}, () => {
                    
                    if(offsetRow){
                        goBackToScrollPositionByPage('/Werkbonnen');
                        localStorage.removeItem("offsetRow");
                    }    
                });
                return; 
            }
        }

    
        Request.Call(url, 'get', null, function(response){
            
            let data = [];

            response.data.werkbonnen.map((val) => {
                let subTitle;
                let datum = (val.datum) ? format(parseISO(val.datum), 'dd-MM-yyyy') : null;
                
                if(datum && val.firstname){
                    subTitle = datum + ' - ' + val.firstname + ' ' + val.lastname;
                }else{
                    subTitle = datum;
                }

                data.push({id: val.id, title: val.straat + ' ' + val.huisnummer, subTitle: subTitle, titleLeft: '<p>test</p>', titleRight: val.uren, titleRightIcon: {icon: 'clock'}});
                return false;
            });

            let total = self.state.total;

            if(page === self.state.page || force === true){
                self.setState({ werkbonnen: [...data], loading: false, page: pageNumber});
                localStorage.setItem("localWerkbonnen", JSON.stringify({data: [...data], page: pageNumber}))
                total.value = parseFloat(response.data.totalUren);
            }else{
                self.setState({ werkbonnen: [...self.state.werkbonnen, ...data], page: pageNumber, loading: false});
                localStorage.setItem("localWerkbonnen", JSON.stringify({data:  [...self.state.werkbonnen, ...data], page: pageNumber}))
                if(response.data.totalUren){
                    total.value = (self.state.total.value + parseFloat(response.data.totalUren));
                }else{
                    total.value = self.state.total.value;
                }
            }

            self.setState({total: total});
        });
    }

    RedirectWerkbon = () => {
        history.push('/Werkbonnen/Nieuw')
    }

    rowClick = (itemId) => {        
        history.push('/Werkbonnen/' + itemId);
    }

    handleFilter = (filters, page, force = true) => {

        let mappedFilters = [];
        let filter;

        filters.map((item, key) => {

            switch(item.type){

                default:
                    if(item.value){
                        filter = item.name+'='+item.value;
                        mappedFilters.push(filter);
                    }
                break;
            }
        });

        if(page){
            this.getWerkbonnen(mappedFilters.join('&'), page, force);
        }else{
            this.getWerkbonnen(mappedFilters.join('&'), this.state.page, force);
        }
    }

    checkBox = () => {
        this.forceUpdate()
    }

    downloadWerkbonnen = () => {
        
        Request.Call('/Werkbonnen/Download', 'file', this.state.overviewCheckboxes.items, function(response){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Werkbonnen.pdf');
            document.body.appendChild(link);
            link.click();
        });
    }

    deleteWerkbon = (id) => {
        const self = this;

        Request.Call('/Werkbonnen/' + id, 'delete', null, function(response){
            toast.success('Werkbon is verwijderd');
            self.getWerkbonnen(self.state.filters, 1, true);
        });
    }

    render(){

        const delWerkbon = (Auth.AuthUser('allWerkbonnen')) ? this.deleteWerkbon : false;

        return (
            <div className="overzicht">   
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    <Title title="Mijn werkbonnen" size="3"></Title>
                    <FontAwesomeIcon className="plusIcon" icon="plus" size="lg" onClick={this.RedirectWerkbon}/>
                    {this.state.overviewCheckboxes && 
                        <Button className="exportButton" onClick={this.downloadWerkbonnen} disabled={(this.state.overviewCheckboxes && !this.state.overviewCheckboxes.items.length > 0)} variant="contained">PDF</Button>
                    }
                </div>

                <MobileOVerview total={this.state.total} checkboxes={this.state.overviewCheckboxes} name="werkbonnen" handleFilter={this.handleFilter} filters={this.state.filters} loading={this.state.loading} deleteRow={delWerkbon} pageLoad={this.getWerkbonnen} limit={this.state.limit} page={this.state.page} data={this.state.werkbonnen} rowClick={this.rowClick} type="werkbon"></MobileOVerview>
            </div>
        )
    }
}

export default Werkbonnen