import React from "react";
import { Grid, touchRippleClasses } from "@mui/material";
import { format, eachDayOfInterval , startOfWeek, endOfWeek, isToday, subDays} from 'date-fns'
import { nl } from "date-fns/locale";
import history from '../../components/history';
import Title from "../Title";
import formatDate from "./formatDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomDialog from "../Dialog";
import { AuthUser } from "../Auth/Auth";
import { isMobile } from "../Mobile";
import { grid } from "@mui/system";
import * as Auth from '../../components/Auth/Auth';

class Calendar extends React.Component {

    constructor(props){

        super(props);
        
        let daysOfWeek = this.getDaysOfWeek();

        let startDate = startOfWeek(new Date(), {weekStartsOn: 1});
        let endDate = endOfWeek(new Date(), {weekStartsOn: 1});

        if(props.startDate){
            startDate = props.startDate;
        }

        if(props.endDate){
            endDate = props.endDate;
        }

        if(localStorage.getItem('planningDates')){
            startDate = localStorage.getItem('planningDates').split(',')[0];
        }

        if(localStorage.getItem('planningDates')){
            endDate = localStorage.getItem('planningDates').split(',')[1];
        }

        this.state = {
            calendarItems: props.data,
            addAfspraak: props.addAfspraak,
            date: (props.startDate) ? props.startDate : format(new Date(), 'yyyy-MM-dd'),
            dialogContent: '',
            collegas: [],
            startOfWeekDate: startDate,
            endOfWeekDate: endDate,
            daysOfWeek: daysOfWeek,
            month: format(new Date(), 'MMMM', { locale: nl }),
            year: format(new Date(), 'yyyy', { locale: nl }),
            draggingId: null,
            dragEndKey: null,
            refresh: true,
            deleteId: null,
            deleteDialog: false,
            werkomschrijving: '',
            showDialogWerkomschrijving: false,
        }     

        this.dragStart = this.dragStart.bind(this);
        this.dragend = this.dragend.bind(this);
        this.dragenter = this.dragenter.bind(this);
        this.dragleave = this.dragleave.bind(this);
    }

    
    getDifference = (prevProps, type) => {

        if(JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)){
            return true;
        }

        if(prevProps.startDate != this.props.startDate && type === 'date'){
            return true;
        }

        return false;
    }

    componentWillUpdate = (prevProps, prevState) => {
        
        if(this.getDifference(prevProps, 'data')){
            this.setState({calendarItems: prevProps.data});
        }

        if(this.getDifference(prevProps, 'date')){ 
            this.setState({date: prevProps.startDate, startOfWeekDate: prevProps.startDate, endOfWeekDate: prevProps.endDate, daysOfWeek: this.getDaysOfWeek(prevProps.startDate, prevProps.endDate)});
        }
    }

    getDaysOfWeek = (startOfWeekDate = null, endOfWeekDate = null) => {
        startOfWeekDate = (startOfWeekDate) ? new Date(startOfWeekDate) : startOfWeek(new Date(), {weekStartsOn: 1});
        endOfWeekDate = (endOfWeekDate) ? new Date(endOfWeekDate) : subDays(endOfWeek(new Date(), {weekStartsOn: 1}), 1);

        let daysOfWeek = eachDayOfInterval ({
            start: startOfWeekDate,
            end: endOfWeekDate
        });

        this.setState({month: format(startOfWeekDate, 'MMMM', { locale: nl }), year: format(startOfWeekDate, 'yyyy', { locale: nl })});      

        return daysOfWeek;
    }

    dragStart = (id) => {
        this.setState({draggingId: id});
    }

    dragend = (e) => {
        this.updateAfspraakDate(this.state.draggingId, this.state.daysOfWeek[this.state.dragEndKey]);
    }

    dragenter = (e) => {
        const element = e.target.closest('.dayItem');
        element.classList.toggle('hover');
        this.setState({dragEndKey: element.getAttribute('data-key')});
    }

    dragleave = (e) => {
        e.target.closest('.dayItem').classList.toggle('hover');
    }

    updateAfspraakDate = (id, newDate) => {
        this.setState({refresh: true});
        this.props.updateAfspraak(id, newDate);
    }

    openAfspraak = (event, id) => {
        if(event.target.nodeName.toLowerCase() === 'div'){
            history.push('/Planning/' + id);
        }
    }
    getAfsprakenByDate = (date) => {
        return this.state.calendarItems.filter(obj => {
            return obj.date === formatDate(date, 'yyyy-MM-dd').toString()
        });
    }

    navigateMaps = (mapsUrl) => {
        window.open(mapsUrl);
    }

    deleteAfspraak = (id) => {
        this.setState({deleteId: id, deleteDialog: true, showDialogWerkomschrijving: false});
    }

    deleteAfspraakConfirmed = () => {
        this.setState({deleteDialog: false});
        this.props.deleteAfspraak(this.state.deleteId);
    }

    openWerkomschrijving = (werkomschrijving) => {

        this.setState(
            {showDialogWerkomschrijving: true, werkomschrijving: werkomschrijving, deleteDialog: false}
        );
    }

    openTask = (id) => {
        history.push('/Taken/' + id);
    }

    render(){

        return (
            <div className="calendar">
                <CustomDialog open={this.state.deleteDialog} title="Afspraak verwijderen" content="Weet je zeker dat je deze afspraak wilt verwijderen?" buttons={[{title: 'Ja', onClick: this.deleteAfspraakConfirmed}, {title: 'Nee'}]}/>
                <CustomDialog open={this.state.showDialogWerkomschrijving} title="Werkomschrijving" content={this.state.werkomschrijving} buttons={[{title: 'Sluiten'}]}/>

                <div className="currentDate"><label className="month">{this.state.month}</label> <label className="year">{this.state.year}</label></div>
                <Grid container minHeight="70vh">
                    {
                        this.state.daysOfWeek.map((day, key) => {
                            return (
                                <Grid data-key={key} className="dayItem" onDragEnter={this.dragenter} onDragLeave={this.dragleave} item md={2}>
                                    { isToday(day) ?
                                            <div className="title today"><label className="today">{ format(day, 'EEEEEE', { locale: nl }) } { format(day, 'dd', { locale: nl }) }</label></div>
                                        :
                                            <div className="title">{ format(day, 'EEEEEE', { locale: nl }) } { format(day, 'dd', { locale: nl }) }</div>
                                    }
                                    <Grid container className="listItems">

                                        {
                                            this.getAfsprakenByDate(day).map((calendarItem, itemKey) => {
                                                return (
                                                    <Grid draggable="true" onClick={(e) => {this.openAfspraak(e, calendarItem.id)}} onDragStart={() => this.dragStart(calendarItem.id)} onDragEnd={this.dragend} className="calendar-item" xs={12} item>
                                                        <Grid container>
                                                            <Grid xs={10} item>
                                                                <div className="item-time">{calendarItem.startTime} - {calendarItem.endTime}</div>
                                                                <Grid className="item-content" container>
                                                                    <Grid xs={12} item>
                                                                        {calendarItem.title}, {calendarItem.subTitle} <br/>
                                                                        {calendarItem.opdrachtgever} <br />
                                                                        <p className="werkomschrijving-planning" onClick={(e) => {this.openWerkomschrijving(calendarItem.werkomschrijving)}}>{calendarItem.werkomschrijving}</p>
                                                                        
                                                                    </Grid>
                                                                    {
                                                                        calendarItem.gebruikers &&
                                                                            <Grid xs={12} item className="users">
                                                                                {
                                                                                    calendarItem.gebruikers.join(', ')
                                                                                }
                                                                            </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                            <Grid xs={2} item >
                                                                <Grid container >
                                                                    {
                                                                        calendarItem.maps &&
                                                                            <Grid xs={12} item className="text-align-center">
                                                                                <FontAwesomeIcon icon="map-marked-alt" size="lg" className="pointer mt-05" onClick={(e) => this.navigateMaps(calendarItem.maps)}/>    
                                                                            </Grid>
                                                                    }
                                                                    {
                                                                        calendarItem.hasTask > 0 &&
                                                                            <Grid xs={12} item className="text-align-center">
                                                                                <FontAwesomeIcon icon="tasks" size="lg" className="pointer mt-05" onClick={(e) => this.openTask(calendarItem.hasTask)}/>    
                                                                            </Grid>
                                                                    }
                                                                    {
                                                                        Auth.AuthUser('editPlanning') && 
                                                                            <Grid xs={12} item className="text-align-center">
                                                                                <FontAwesomeIcon icon="trash" size="lg" className="pointer mt-05" onClick={(e) => this.deleteAfspraak(calendarItem.id)}/>
                                                                            </Grid>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </div>
        )
    }
}

export default Calendar;