
import Tiles from '../components/Menu/Tiles/Tiles';

const Overzicht = (props) => {

    return (
        <>   
            <Tiles></Tiles>
        </>
    )
}

export default Overzicht