import React from "react";
import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Request from "../../components/Call";
import { AuthUser } from "../../components/Auth/Auth";
import history from "../../components/history";
import MobileOVerview from "../../components/Overview/Mobile"

class Taken extends React.Component {

    constructor(props){
        super(props);  
        
        this.state = {
            filters: this.getFilters(),
            taken: [],
            page: 1,
            limit: 20,
            loading: false
        }
    }

    componentDidMount() { 
        this.handleFilter(this.state.filters)
    }
      
    getFilters = () => {
        return [
            {name: 'afgerond', label: 'Afgeronde taken tonen', values: [true, false], value: false, type: 'checkbox'},
            {name: 'searchField', label: 'Adres', value: '', type: 'text', filterType: 'like'}
        ]
    }

    rowClick = (id) => {
        history.push('/Taken/' + id)
    }

    handleFilter = (filters, page, force = true) => {

        let mappedFilters = [];
        let filter;

        filters.map((item, key) => {

            switch(item.type){

                default:
                    if(item.value){
                        filter = item.name+'='+item.value;
                        mappedFilters.push(filter);
                    }
                break;

                case 'checkbox':
                    if(item.valueType){
                        if(item.valueType.type === 'both'){
                            if(item.value === item.valueType.ifValueIs){
                                item.selectedValue = item.values.join(',');     
                            }else{
                                item.selectedValue = (!item.value);
                            }       
                        }
                    }else{
                        item.selectedValue = (item.value === '') ? false : (item.value === 'Ja') ? true : item.value;
                    }
        
                    filter = item.name+'='+item.selectedValue;

                    mappedFilters.push(filter);
                break;

            }
            
        });

        if(page){
            this.getTaken(mappedFilters.join('&'), page, force);
        }else{
            this.getTaken(mappedFilters.join('&'), this.state.page, force);
        }
    }

    getTaken = (filters, page, force) => {

        if(typeof filters === 'object'){
            this.handleFilter(filters, page, false)
        }

        const self = this;
        
        if(this.state.loading) return;
        this.setState({loading: true});

        let pageNumber = (page) ? page : this.state.page;


        Request.Call('/Taken/Overview?'+filters+'&page='+pageNumber+'&limit='+this.state.limit, 'get', null, function(response){
            
            let data = [];

            response.data.map((val) => {

                let title = val.straat + ' ' + val.huisnummer + ',' + val.plaats;
                
               
                data.push({id: val.id, title: title });
                return false;
            });            

            if(page === self.state.page || force){
                self.setState({ taken: [...data], loading: false, page: page});
            }else{
                self.setState({ taken: [...self.state.taken, ...data], loading: false, page: page});
            }
            
        });
    }

    addTaak = (time) => {
        if(typeof time === 'string'){
            history.push('/Taken/' + time);
        }else{
            history.push('/Taken/Nieuw');
        }
    }
    
    render(){
        return (
            <div className="overzicht">
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    <Title title="Taken" size="3"></Title>
                    {
                        AuthUser('editTaken') &&
                            <FontAwesomeIcon className="plusIcon" icon="plus" size="lg" onClick={this.addTaak}/>
                    }
                </div>
                <MobileOVerview name="taken" loading={this.state.loading} pageLoad={this.getTaken} limit={this.state.limit} page={this.state.page} data={this.state.taken} rowClick={this.rowClick} handleFilter={this.handleFilter} filters={this.state.filters}></MobileOVerview>

            </div>
        )
    }
}

export default Taken;