import Cookies from 'js-cookie';
import history from '../history';

export const Logoff = (route) => {
    Object.keys(Cookies.get()).forEach(function(cookieName) {
        Cookies.remove(cookieName);
    });

    localStorage.clear();

    history.push('/')

    return (
        <div>   
            Je wordt uitgelogd.
        </div>
    )

    return true;
}