import React from "react";
import Title from "../../components/Title"
import Breadcrumb from "../../components/Menu/Breadcrumb"
import OverzichtFacturatie from "./Overview";
import * as Request from "../../components/Call";
import { format, parseISO  } from 'date-fns'
import { Button } from "@mui/material";
import history from '../../components/history';

class FacturatieDetails extends React.Component {

    constructor(props){
        
        super(props);

        const werkbonId = (props.match.params.adresId) ? props.match.params.adresId : 0;

        if(werkbonId === 0){
            alert('Oops..');
        }

        this.state = {
            werkbonId: werkbonId,
            address: '',
            werkbonnen: {
                data: [], 
                columns: [
                    {label: 'Adres', name: 'address'},
                    {label: 'Werkomschrijving', name: 'werkomschrijving'},
                    {label: 'Datum', name: 'datum'},
                ],
                icons: [
                    {name: 'pencil-alt', size: 'lg', position: 'after', action: this.editWerkbon},
                ],
                limit: 10 ,
                loading: true,
                page: 1,
                total: 0,
                totalPages: 0
            },
            materiaal:
            {
                data: [],
                columns: [
                    {label: 'Artikelnr', name: 'artikelnr'},
                    {label: 'Omschrijving', name: 'omschrijving'},
                    {label: 'Hoeveelheid', name: 'hoeveelheid'},
                ],
                limit: 10 ,
                loading: true,
                page: 1,
                total: 0,
                totalPages: 0
            }
        };       

        this.getWerkbonnen(false, 1, 10)
        this.getMateriaal(false, 1, 10)
    }

    getWerkbonnen = (filters, page, limit) => {

        const self = this;
        let joinedFilters;
        let url;
        limit = (limit) ? limit : this.state.werkbonnen.limit;

        if(filters){
            joinedFilters = filters.join('&');
            url = '/Facturatie/Werkbonnen/'+this.state.werkbonId+'?'+joinedFilters+'&page='+page+'&limit='+limit
        }else{
            url = '/Facturatie/Werkbonnen/'+this.state.werkbonId+'?page='+page+'&limit='+limit;
        }

        self.setState( prevState => ({ materiaal: {...prevState.materiaal, loading: true} }));

        Request.Call(url, 'get', null, function(response){
            
            let data = [];

            response.data.data.map((val) => {
                let datum = (val.datum) ? format(parseISO(val.datum), 'dd-MM-yyyy') : null;
                data.push({id: val.id, address: val.address, werkomschrijving: val.werkomschrijving, datum: datum});
                return false;
            });


            const totalPages = Math.ceil(response.data.total/self.state.werkbonnen.limit);
            self.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, data: data, page: page, total: response.data.total, totalPages: totalPages, loading: false}, address: response.data.address}));
        });
    }

    getMateriaal = (filters, page, limit) => {

        const self = this;
        let joinedFilters;
        let url;
        limit = (limit) ? limit : this.state.materiaal.limit;

        if(filters){
            joinedFilters = filters.join('&');
            url = '/Facturatie/Werkbonnen/'+this.state.werkbonId+'/materiaal?'+joinedFilters+'&page='+page+'&limit='+limit
        }else{
            url = '/Facturatie/Werkbonnen/'+this.state.werkbonId+'/materiaal?page='+page+'&limit='+limit;
        }

        self.setState( prevState => ({ materiaal: {...prevState.materiaal, loading: true} }));

        Request.Call(url, 'get', null, function(response){
            
            let data = [];

            response.data.data.map((val) => {
                let datum = (val.datum) ? format(parseISO(val.datum), 'dd-MM-yyyy') : null;
                data.push({id: val.werkbonId, artikelnr: val.artikelnr, omschrijving: val.omschrijving, hoeveelheid: val.hoeveelheid});
                return false;
            });


            const totalPages = Math.ceil(response.data.total/self.state.materiaal.limit);
            self.setState( prevState => ({ materiaal: {...prevState.materiaal, data: data, page: page, total: response.data.total, totalPages: totalPages, loading: false}}));
        });
    }

    prevNextPage = (type, prevNext) => {

        let page;

        switch(type){

            case "werkbonnen":

                if(prevNext === 'prev'){
                    if(this.state.werkbonnen.page === 1){
                        return;
                    }
                    page = (this.state.werkbonnen.page-1);
                }else{
                    if((this.state.werkbonnen.limit * this.state.werkbonnen.page) >= this.state.werkbonnen.total){
                        return;
                    }
                    page = (this.state.werkbonnen.page+1);
                }

                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, page: page}}));
                this.getWerkbonnen(this.state.werkbonnen.filters, page);
            break;

            case "materiaal":
                if(prevNext === 'prev'){
                    if(this.state.materiaal.page === 1){
                        return;
                    }
                    page = (this.state.materiaal.page-1);
                }else{
                    if((this.state.materiaal.limit * this.state.materiaal.page) >= this.state.materiaal.total){
                        return;
                    }
                    page = (this.state.materiaal.page+1);
                }

                this.setState( prevState => ({ materiaal: {...prevState.materiaal, page: page}}));
                this.getMateriaal(this.state.materiaal.filters, page);
            break;
        }
    }

    changePage = (type, page) => {

        switch(type){

            case "werkbonnen":
                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, page: page}}));
                this.getWerkbonnen(this.state.werkbonnen.filters, page);
            break;

            case "materiaal":
                this.setState( prevState => ({ materiaal: {...prevState.materiaal, page: page}}));
                this.getMateriaal(this.state.materiaal.filters, page);
            break;
        }
    }

    changeLimit = (type, limit) => {

        switch(type){

            case "werkbonnen":
                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, limit: limit}}));
                this.getWerkbonnen(this.state.werkbonnen.filters, 1, limit);
            break;

            case "materiaal":
                this.setState( prevState => ({ materiaal: {...prevState.materiaal, limit: limit}}));
                this.getMateriaal(this.state.materiaal.filters, 1, limit);
            break;
        }
    }

    downloadOverzicht = () => {
        const self = this;

        Request.Call('/Facturatie/Werkbonnen/'+this.state.werkbonId+'/Download', 'file', {}, function(response){
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'Overzicht - '+self.state.address+'.pdf');
            document.body.appendChild(link);
            link.click();
        });
    }

    editWerkbon = (rowIndex) => {
        if(this.state.werkbonnen.data[rowIndex]){
            history.push({pathname: '/Werkbonnen/'+this.state.werkbonnen.data[rowIndex]['id'], state: { from: '/Facturatie/'+this.state.werkbonId }});
        }
    }

    render(){

        return (
            <div className="overzicht"> 
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    <Title title="Facturatie details" size="3"></Title>
                    <Button className="exportButton" onClick={this.downloadOverzicht} variant="contained">Download overzicht</Button>
                </div>
                <div>{this.state.address}    </div>

                <Title title="Gekoppelde werkbonnen" size="4"></Title>
                <OverzichtFacturatie showColumns={true} data={this.state.werkbonnen.data} columns={this.state.werkbonnen.columns} icons={this.state.werkbonnen.icons} totalPages={this.state.werkbonnen.totalPages} prevPage={() => this.prevNextPage('werkbonnen', 'prev')} nextPage={() => this.prevNextPage('werkbonnen', 'next')} currentPage={this.state.werkbonnen.page} changeLimit={(e) => this.changeLimit('werkbonnen', e.target.value)} changePage={(e) => this.changePage('werkbonnen', e.target.value)}></OverzichtFacturatie>

                <Title title="Materiaal" size="4"></Title>
                <OverzichtFacturatie showColumns={true} data={this.state.materiaal.data} columns={this.state.materiaal.columns} totalPages={this.state.materiaal.totalPages} prevPage={() => this.prevNextPage('materiaal', 'prev')} nextPage={() => this.prevNextPage('materiaal', 'next')} currentPage={this.state.materiaal.page} changeLimit={(e) => this.changeLimit('materiaal', e.target.value)} changePage={(e) => this.changePage('materiaal', e.target.value)}></OverzichtFacturatie>
            </div>
        )
    }
}

export default FacturatieDetails