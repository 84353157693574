
import React from "react";
import { TextField, Button, Autocomplete, createFilterOptions, Select, InputAdornment } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Box from '@mui/material/Box';
import Title from "../../../components/Title"
import Breadcrumb from "../../../components/Menu/Breadcrumb"
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Upload from "../../../components/Inputs/Upload";
import history from '../../../components/history';
import * as Request from "../../../components/Call";
import { toast } from 'react-toastify';

class ChecklistWoningForm extends React.Component {

    constructor(props){
        
        super(props);

        let straat, huisnummer, plaats, opdrachtgever;
        const checklistId = (props.match) ? props.match.params.checklistId : null
        const type = (props.match) ? 'edit' : 'add';

        if(localStorage.createChecklistWoning){
            let data = JSON.parse(localStorage.createChecklistWoning);
            opdrachtgever = (data.opdrachtgever) ? data.opdrachtgever : '';
            straat = (data.straat) ? data.straat : '';
            huisnummer = (data.huisnummer) ? data.huisnummer : '';
            plaats = (data.plaats) ? data.plaats : '';
            localStorage.removeItem('createChecklistWoning');
        }else{
            straat = '';
            huisnummer = '';
            plaats = '';
            opdrachtgever = 'Uwoon';
        }

        this.state = {
            id: checklistId,
            title: props.title,
            type: type,
            saving: false,
            checklistFields: {
                straat: straat,
                huisnummer: huisnummer,
                plaats: plaats,
                opdrachtgever: opdrachtgever,
                redenVanInspectie: 'Oplevering',
                netaansluiting: '1-fase',
                aardverspreidingsweerstandVoldoet: false,
                aardverspreidingsweerstand: '',
                metalenGestellenVereffendInMeterkastVoldoet: false,
                metalenGestellenVereffendInMeterkast: '',
                eersteAardlekschakelaarVoldoet: false,
                eersteAardlekschakelaarMS: '',
                eersteAardlekschakelaarMA: '',
                tweedeAardlekschakelaarVoldoet: false,
                tweedeAardlekschakelaarMS: '',
                tweedeAardlekschakelaarMA: '',
                eersteAardlekInstallatieautomaatVoldoet: false,
                eersteAardlekInstallatieautomaatMS: '',
                eersteAardlekInstallatieautomaatMA: '',
                tweedeAardlekInstallatieautomaatVoldoet: false,
                tweedeAardlekInstallatieautomaatMS: '',
                tweedeAardlekInstallatieautomaatMA: '',
                derdeAardlekInstallatieautomaatVoldoet: false,
                derdeAardlekInstallatieautomaatMS: '',
                derdeAardlekInstallatieautomaatMA: '',
                vierdeAardlekInstallatieautomaatVoldoet: false,
                vierdeAardlekInstallatieautomaatMS: '',
                vierdeAardlekInstallatieautomaatMA: '',
                vijfdeAardlekInstallatieautomaatVoldoet: false,
                vijfdeAardlekInstallatieautomaatMS: '',
                vijfdeAardlekInstallatieautomaatMA: '',
                hoofdschakelaarAanwezig: false,
                bijzonderheden: '',
                gasdrukvalVoldoet: false,
                gasdrukvalBeginstand: '',
                gasdrukvalEindstand: '',
                bijzonderhedenGas: '',
                waterVoldoet: false,
                groepenverklaringaanwezigVoldoet: false,
                rookmeldersVoldoet: false,
                algemeneIndrukInstallatieVoldoet: false,
                opmerkingen: '',
                oldFiles: (props.oldFiles) ? props.oldFiles : [],
            },
            files: [],
            errors: [],
        }
    }

    componentDidMount(prevProps, prevState) {
        switch(this.state.type){
            case "edit":
                this.getChecklistById(this.state.id)
            break;

            default:
                return false;
            break;
        } 
    }

    handleChange = (event, stateType = 'checklistFields') => {

        switch(event.target.type){

            default:
                let value = event.target.value;
                this.setState( prevState => ({ [stateType]: {...prevState[stateType], [event.target.name]: value} }));
            break;

            case "checkbox":
                let val;

                if(this.state[stateType][event.target.name] == 0){
                    val = false;
                }else if(this.state[stateType][event.target.name] == 1){
                    val = true;
                }else{
                    val = this.state[stateType][event.target.name];
                }

                this.setState(prevState => ({ [stateType]: {...prevState[stateType], [event.target.name]: (!val)}}));
            break;
            
        }
    }

    getChecklistById = (id) => {
        
        const self = this;
        
        Request.Call('/Checklist/Woningen/' + id, 'get', null, function(response){
    
            if(response === undefined){
                return false
            }

            if(response.data.msg){
                history.push('/Checklist-woningen');
                return false;
            }

            self.setState({ checklistFields: response.data });
        });
    }

    replaceComma = (value) => {
        return value.replace(',', '.');
    }

    saveChecklist = (event) => {

        event.preventDefault();

        if(this.state.saving) return;
        
        this.setState({saving: true});

        const postBody = {
            straat: this.state.checklistFields.straat,
            huisnummer: this.state.checklistFields.huisnummer,
            plaats: this.state.checklistFields.plaats,
            opdrachtgever: this.state.checklistFields.opdrachtgever,
            redenVanInspectie: this.state.checklistFields.redenVanInspectie,
            netaansluiting: this.state.checklistFields.netaansluiting,
            aardverspreidingsweerstandVoldoet: this.state.checklistFields.aardverspreidingsweerstandVoldoet,
            aardverspreidingsweerstand: this.replaceComma(this.state.checklistFields.aardverspreidingsweerstand),
            metalenGestellenVereffendInMeterkastVoldoet: this.state.checklistFields.metalenGestellenVereffendInMeterkastVoldoet,
            metalenGestellenVereffendInMeterkast: this.replaceComma(this.state.checklistFields.metalenGestellenVereffendInMeterkast),
            eersteAardlekschakelaarVoldoet: this.state.checklistFields.eersteAardlekschakelaarVoldoet,
            eersteAardlekschakelaarMS: this.replaceComma(this.state.checklistFields.eersteAardlekschakelaarMS),
            eersteAardlekschakelaarMA: this.replaceComma(this.state.checklistFields.eersteAardlekschakelaarMA),
            tweedeAardlekschakelaarVoldoet: this.state.checklistFields.tweedeAardlekschakelaarVoldoet,
            tweedeAardlekschakelaarMS: this.replaceComma(this.state.checklistFields.tweedeAardlekschakelaarMS),
            tweedeAardlekschakelaarMA: this.replaceComma(this.state.checklistFields.tweedeAardlekschakelaarMA),
            eersteAardlekInstallatieautomaatVoldoet: this.state.checklistFields.eersteAardlekInstallatieautomaatVoldoet,
            eersteAardlekInstallatieautomaatMS: this.replaceComma(this.state.checklistFields.eersteAardlekInstallatieautomaatMS),
            eersteAardlekInstallatieautomaatMA: this.replaceComma(this.state.checklistFields.eersteAardlekInstallatieautomaatMA),
            tweedeAardlekInstallatieautomaatVoldoet: this.state.checklistFields.tweedeAardlekInstallatieautomaatVoldoet,
            tweedeAardlekInstallatieautomaatMS: this.replaceComma(this.state.checklistFields.tweedeAardlekInstallatieautomaatMS),
            tweedeAardlekInstallatieautomaatMA: this.replaceComma(this.state.checklistFields.tweedeAardlekInstallatieautomaatMA),
            derdeAardlekInstallatieautomaatVoldoet: this.state.checklistFields.derdeAardlekInstallatieautomaatVoldoet,
            derdeAardlekInstallatieautomaatMS: this.replaceComma(this.state.checklistFields.derdeAardlekInstallatieautomaatMS),
            derdeAardlekInstallatieautomaatMA: this.replaceComma(this.state.checklistFields.derdeAardlekInstallatieautomaatMA),
            vierdeAardlekInstallatieautomaatVoldoet: this.state.checklistFields.vierdeAardlekInstallatieautomaatVoldoet,
            vierdeAardlekInstallatieautomaatMS: this.replaceComma(this.state.checklistFields.vierdeAardlekInstallatieautomaatMS),
            vierdeAardlekInstallatieautomaatMA: this.replaceComma(this.state.checklistFields.vierdeAardlekInstallatieautomaatMA),
            vijfdeAardlekInstallatieautomaatVoldoet: this.state.checklistFields.vijfdeAardlekInstallatieautomaatVoldoet,
            vijfdeAardlekInstallatieautomaatMS: this.replaceComma(this.state.checklistFields.vijfdeAardlekInstallatieautomaatMS),
            vijfdeAardlekInstallatieautomaatMA: this.replaceComma(this.state.checklistFields.vijfdeAardlekInstallatieautomaatMA),
            hoofdschakelaarAanwezig: this.state.checklistFields.hoofdschakelaarAanwezig,
            bijzonderheden: this.state.checklistFields.bijzonderheden,
            gasdrukvalVoldoet: this.state.checklistFields.gasdrukvalVoldoet,
            gasdrukvalBeginstand: this.replaceComma(this.state.checklistFields.gasdrukvalBeginstand),
            gasdrukvalEindstand: this.replaceComma(this.state.checklistFields.gasdrukvalEindstand),
            bijzonderhedenGas: this.state.checklistFields.bijzonderhedenGas,
            waterVoldoet: this.state.checklistFields.waterVoldoet,
            groepenverklaringaanwezigVoldoet: this.state.checklistFields.groepenverklaringaanwezigVoldoet,
            rookmeldersVoldoet: this.state.checklistFields.rookmeldersVoldoet,
            algemeneIndrukInstallatieVoldoet: this.state.checklistFields.algemeneIndrukInstallatieVoldoet,
            opmerkingen: this.state.checklistFields.opmerkingen,
            oldFiles: this.state.checklistFields.oldFiles
        }
        
        if(this.state.id >  0){
            console.log(this.state.checklistFields);
            Request.Call('/Checklist/Woningen/' + this.state.id + '/edit', 'post', postBody, this.handleResponse, this.state.files);
        }else{
            Request.Call('/Checklist/Woningen', 'post', postBody, this.handleResponse, this.state.files);
        }
    }

    handleResponse = (response) => {
        const self = this;

        this.setState({saving: false});

        if(response.status === 200) {
            toast.success('Checklist is opgeslagen.');
            history.push('/Checklist-woningen');
        }

        if(response.data.fields){
            this.setState({errors: response.data.fields, error: true});
            window.scrollTo(0, 0);
        }
    }

    showError = (field) => {
        if(this.state.errors.includes(field)){
            return true;
        }
    }
    
    render(){
        return (
            <Box className="details" noValidate sx={{ mt: 1 }}>        
                <div className="overzicht">   
                    <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                    <Title title={this.state.title} size="5" ></Title>
                </div>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="straat"
                    label="Straat"
                    type="text"
                    variant="filled" 
                    value={this.state.checklistFields.straat}
                    onChange={(e) => this.handleChange(e)}
                    error={this.showError('straat')}
                    />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="huisnummer"
                    label="Huisnummer"
                    type="text"
                    variant="filled" 
                    value={this.state.checklistFields.huisnummer}
                    onChange={(e) => this.handleChange(e)}
                    error={this.showError('huisnummer')}
                    />   
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="plaats"
                    label="Plaats"
                    type="text"
                    variant="filled" 
                    value={this.state.checklistFields.plaats}
                    onChange={(e) => this.handleChange(e)}
                    error={this.showError('plaats')}
                    /> 
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="opdrachtgever"
                    label="opdrachtgever"
                    type="text"
                    variant="filled" 
                    value={this.state.checklistFields.opdrachtgever}
                    onChange={(e) => this.handleChange(e)}
                    error={this.showError('opdrachtgever')}
                    /> 
                    
                <Box sx={{ minWidth: 120, my: 2}}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="redenInspectie">Reden van inspectie</InputLabel>
                        <Select
                            labelId="redenInspectie"
                            id="redenInspectieField"
                            value={this.state.checklistFields.redenVanInspectie}
                            label="Reden van inspectie"
                            name="redenVanInspectie"
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('redenVanInspectie')}
                        >
                            <MenuItem value="Oplevering">Oplevering</MenuItem>
                            <MenuItem value="Wijziging">Wijziging</MenuItem>
                            <MenuItem value="Periodieke inspectie">Periodieke inspectie</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Box sx={{ minWidth: 120, my: 3 }}>
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="netaansluiting">Netaansluiting</InputLabel>
                        <Select
                            labelId="netaansluiting"
                            id="netaansluitingField"
                            value={this.state.checklistFields.netaansluiting}
                            name="netaansluiting"
                            label="Netaansluiting"
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('netaansluiting')}
                        >
                            <MenuItem value="1-fase">1-fase</MenuItem>
                            <MenuItem value="3-fase">3-fase</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Title title="Metingen elektra" size="7" ></Title>
                <Title title="Aardverspreidingsweerstand" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="aardverspreidingsweerstand"
                        label="Gemeten waarde &#x2126;"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.aardverspreidingsweerstand}
                        onChange={(e) => this.handleChange(e, 'checklistFields')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">&#x2126;</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="aardverspreidingsweerstandVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.aardverspreidingsweerstandVoldoet === '1' || this.state.checklistFields.aardverspreidingsweerstandVoldoet === true)}/>} label="Voldoet" />
                </Box>

                <Title title="Metalen gestellen vereffend in meterkast" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="metalenGestellenVereffendInMeterkast"
                        label="Gemeten waarde &#x2126;"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.metalenGestellenVereffendInMeterkast}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">&#x2126;</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="metalenGestellenVereffendInMeterkastVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.metalenGestellenVereffendInMeterkastVoldoet === '1' || this.state.checklistFields.metalenGestellenVereffendInMeterkastVoldoet === true)}/>} label="Voldoet" />
                </Box>

                
                <Title title="1e Aardlekschakelaar" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="eersteAardlekschakelaarMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.eersteAardlekschakelaarMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="eersteAardlekschakelaarMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.eersteAardlekschakelaarMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="eersteAardlekschakelaarVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.eersteAardlekschakelaarVoldoet === '1' || this.state.checklistFields.eersteAardlekschakelaarVoldoet === true)}/>} label="Voldoet" />
                </Box>
                <Title title="2e Aardlekschakelaar" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="tweedeAardlekschakelaarMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.tweedeAardlekschakelaarMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="tweedeAardlekschakelaarMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.tweedeAardlekschakelaarMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="tweedeAardlekschakelaarVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.tweedeAardlekschakelaarVoldoet === '1' || this.state.checklistFields.tweedeAardlekschakelaarVoldoet === true)}/>} label="Voldoet" />
                </Box>
                <Title title="1e Aardlek installatieautomaat" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="eersteAardlekInstallatieautomaatMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.eersteAardlekInstallatieautomaatMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="eersteAardlekInstallatieautomaatMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.eersteAardlekInstallatieautomaatMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="eersteAardlekInstallatieautomaatVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.eersteAardlekInstallatieautomaatVoldoet === '1' || this.state.checklistFields.eersteAardlekInstallatieautomaatVoldoet === true)}/>} label="Voldoet" />
                </Box>
                <Title title="2e Aardlek installatieautomaat" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="tweedeAardlekInstallatieautomaatMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.tweedeAardlekInstallatieautomaatMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="tweedeAardlekInstallatieautomaatMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.tweedeAardlekInstallatieautomaatMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="tweedeAardlekInstallatieautomaatVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.tweedeAardlekInstallatieautomaatVoldoet === '1' || this.state.checklistFields.tweedeAardlekInstallatieautomaatVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="3e Aardlek installatieautomaat" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="derdeAardlekInstallatieautomaatMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.derdeAardlekInstallatieautomaatMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="derdeAardlekInstallatieautomaatMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.derdeAardlekInstallatieautomaatMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="derdeAardlekInstallatieautomaatVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.derdeAardlekInstallatieautomaatVoldoet === '1' || this.state.checklistFields.derdeAardlekInstallatieautomaatVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="4e Aardlek installatieautomaat" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="vierdeAardlekInstallatieautomaatMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.vierdeAardlekInstallatieautomaatMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="vierdeAardlekInstallatieautomaatMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.vierdeAardlekInstallatieautomaatMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="vierdeAardlekInstallatieautomaatVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.vierdeAardlekInstallatieautomaatVoldoet === '1' || this.state.checklistFields.vierdeAardlekInstallatieautomaatVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="5e Aardlek installatieautomaat" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="vijfdeAardlekInstallatieautomaatMS"
                        label="Gemeten waarde MS"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.vijfdeAardlekInstallatieautomaatMS}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MS</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="vijfdeAardlekInstallatieautomaatMA"
                        label="Gemeten waarde MA"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.vijfdeAardlekInstallatieautomaatMA}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MA</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="vijfdeAardlekInstallatieautomaatVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.vijfdeAardlekInstallatieautomaatVoldoet === '1' || this.state.checklistFields.vijfdeAardlekInstallatieautomaatVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="Hoofdschakelaar aanwezig" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="hoofdschakelaarAanwezig" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.hoofdschakelaarAanwezig === '1' || this.state.checklistFields.hoofdschakelaarAanwezig === true)} />} label="Voldoet" />
                </Box>
                <TextField
                        margin="normal"
                        rows={4} 
                        variant="filled"
                        fullWidth
                        name="bijzonderheden"
                        label="Bijzonderheden"
                        onChange={(e) => this.handleChange(e)}
                        multiline   
                        value={this.state.checklistFields.bijzonderheden}
                        />
                
                <Title title="Metingen gas" size="7" ></Title>
                <Title title="Gasdrukval (max. 1mbar in 180sec)" size="5" margin="0"></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="gasdrukvalBeginstand"
                        label="Beginstand"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.gasdrukvalBeginstand}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MB</InputAdornment>
                        }}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="gasdrukvalEindstand"
                        label="Eindstand"
                        type="text"
                        variant="filled" 
                        value={this.state.checklistFields.gasdrukvalEindstand}
                        onChange={(e) => this.handleChange(e)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">MB</InputAdornment>
                        }}
                        />
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="gasdrukvalVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.gasdrukvalVoldoet === '1' || this.state.checklistFields.gasdrukvalVoldoet === true)} />} label="Voldoet" />
                    <TextField
                        margin="normal"
                        rows={4} 
                        variant="filled"
                        fullWidth
                        name="bijzonderhedenGas"
                        label="bijzonderheden"
                        onChange={(e) => this.handleChange(e)}
                        multiline   
                        value={this.state.checklistFields.bijzonderhedenGas}
                        />
                </Box>

                <Title title="Water" size="7" ></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="waterVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.waterVoldoet === '1' || this.state.checklistFields.waterVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="Groepenverklaring aanwezig" size="7" ></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="groepenverklaringaanwezigVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.groepenverklaringaanwezigVoldoet === '1' || this.state.checklistFields.groepenverklaringaanwezigVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="Rookmelders indien aanwezig gecontroleerd" size="7" ></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="rookmeldersVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.rookmeldersVoldoet === '1' || this.state.checklistFields.rookmeldersVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="Algemene indruk installatie" size="7" ></Title>
                <Box sx={{ minWidth: 120, my: 3, p:1, border: '1px solid grey' }}>
                    <FormControlLabel sx={{my: 0}} control={<Checkbox name="algemeneIndrukInstallatieVoldoet" onChange={(e) => this.handleChange(e)} checked={(this.state.checklistFields.algemeneIndrukInstallatieVoldoet === '1' || this.state.checklistFields.algemeneIndrukInstallatieVoldoet === true)} />} label="Voldoet" />
                </Box>

                <Title title="Foto's" size="5" margin="0"></Title>
                <Upload name="bestanden" label="Bestanden toevoegen.." oldFiles={this.state.checklistFields.oldFiles} files={this.state.files} /><br /><br />

                <Title title="Opmerkingen" size="7" ></Title>
                <TextField
                        margin="normal"
                        rows={4} 
                        variant="filled"
                        fullWidth
                        name="opmerkingen"
                        label="opmerkingen"
                        onChange={(e) => this.handleChange(e)}
                        multiline   
                        value={this.state.checklistFields.opmerkingen}
                        />
                        <Button type="submit" onClick={e => {this.saveChecklist(e)}} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Opslaan</Button>
            </Box> 
                       
        )
    }
}

export default ChecklistWoningForm