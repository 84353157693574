import { icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import React from 'react';

class Overview extends React.Component {
    
    constructor(props){
        
        super(props);

        this.state = {
            data: props.data,
            columns: props.columns,
            icons: props.icons,
            rowClickField: props.rowClickField,
            listTable: props.listTable,
            handleRowClick: props.handleRowClick,
            subData: (props.subData !== false && props.subData !== undefined) ? this.renderSubTable(props.subData) : false,
        };

        //this.handleRowClick = this.handleRowClick.bind(this);
    }

    componentWillReceiveProps = (nextProps) => {
        if(nextProps.renderSubTable !== undefined){
            const rowIndex = nextProps.renderSubTable;
            if(this.state.data[rowIndex]['collapseExpandIcon'] === 'angle-down'){
                // close subtable
                console.log('close subtable');
            }else{
                // open subtable
                this.renderSubTable(rowIndex);
                console.log('render table');
            }
        }
    }

    createTd(data, key){
        return <td key={key}>{ data }</td>
    }

    // handleRowClick(e){

    //     if(e.target.nodeName == 'TD'){
    //         if(e.target.parentNode && e.target.parentNode.hasAttribute(this.state.rowClickField)){
    //             window.location.href = window.location.href + "/" + e.target.parentNode.getAttribute(this.state.rowClickField);
    //         }
    //     }
    // }

    renderTableHeader(){
        if(this.state.columns){
            return <tr className="overviewHeader">
                        {
                            this.state.columns.map((column, index) => {
                                if(column.display !== false){
                                    return <th key={index}>{column.label}</th>
                                }
                            })
                        }
                    </tr>
        }
    }

    handleRowClick = (e, data) => {
        if(!e.target instanceof SVGElement) {
            this.state.handleRowClick(e, data);
        }
    }

    renderSubTable = (rowIndex) => {
        this.renderTableData(rowIndex);
    }

    renderTableRowData = (data, index, subTableColumns = false, subTable = false) => {
        
        const self = this;
        let iconElBefore;
        let iconElAfter;
       
        return <tr id={data.id} onClick={(e) => {this.handleRowClick(e, data)}} key={index} hidden={(subTable !== false)}>
            {iconElBefore}
           
            {
                (this.state.columns && subTable === false) &&
                    this.renderIcons(this.state.columns.find(c => c.type === 'icon'), index)
            }

            {
                Object.keys(data).map((key, idx) => {
                    if(subTable){
                        if(subTableColumns){
                            if(subTableColumns.find(c => c.name === key)){
                                return self.createTd(data[key], idx)
                            } 
                        }else{
                            return self.createTd(data[key], idx)
                        }
                    }else if(this.state.columns){
                        if(this.state.columns.find(o => o.name === key && (!o.display && o.display !== false))){
                            return self.createTd(data[key], idx)
                        }
                    }else{
                        return self.createTd(data[key], idx)
                    }
                })                      
            }    
            {iconElAfter}
        </tr>
    }

    renderTableData(rowIndex = false){

        const self = this
        let subTableColumns;

        return this.state.data.map((data, index) => {         
            
            if(data.subTable){    
                let tableRows = [];

                tableRows.push(self.renderTableRowData(data, index));

                subTableColumns = data.subTable.columns;
                data = data.subTable.data;

                data.map((subData, subIndex) => {    
                    tableRows.push(self.renderTableRowData(subData, subIndex, subTableColumns, true));
                });

                return tableRows; 
                        
            }else{
                return self.renderTableRowData(data, index);
            }
        })
    }

    renderIcons(rowIndex, type){

        const self = this;

        if(this.state.icons && typeof rowIndex !== 'object'){        
            return this.state.icons.map((icon, index) => {
                if(icon.position === type){
                    let iconEl = <FontAwesomeIcon size={(icon.size) ? icon.size : '1x'} onClick={() => { icon.action(rowIndex) }}  icon={icon.name} />
                    return iconEl
                }
            })
        }else if(typeof rowIndex === 'object'){
            const iconData = rowIndex;
            rowIndex = type;

            let iconEl = <td><FontAwesomeIcon size={(iconData.size) ? iconData.size : '1x'} onClick={() => { iconData.action(rowIndex) }}  icon={this.state.data[rowIndex][iconData.name]} /></td>
            return iconEl
        }
    }

    renderTable(){

        const tableHeader = this.renderTableHeader();
        const renderTableData = this.renderTableData();
        
        return (
            <tbody>
                { tableHeader }
                { renderTableData }
            </tbody>
        )
    }

    render(){

        return (
            <div className="Overview">
                <table className={`${this.state.listTable ? "listTable" : ""}`} >
                    {this.renderTable()}
                </table>
            </div>
        )
    }
}

export default Overview;
