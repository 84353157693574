import React from "react";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import { nl } from "date-fns/locale";
import { format } from 'date-fns'
import Box from '@mui/material/Box';
import { TextField } from "@mui/material";
import dayjs, { Dayjs } from 'dayjs';

class DateRangePickerInp extends React.Component {

    constructor(props){
        
        super(props);
        
        this.state = {
            date: ['', ''],
        };
    }

    changeRange = (value) => {
        const formattedDates = [];
        if(!value) return true;


        value.map((datevalue) => {

            if(datevalue){
                
                let newDate;
                if((new Date(datevalue) !== "Invalid Date") && !isNaN(new Date(datevalue))){
                    newDate = format(new Date(datevalue), 'yyyy-MM-dd');
                }
                
                formattedDates.push(newDate);
                return true;
            }

            formattedDates.push(datevalue);
        });

        this.setState({date: formattedDates});
    }

    render(){

        return (
            <LocalizationProvider key={1} locale={nl} dateAdapter={AdapterDateFns}>
            <DateRangePicker
                startText="Vanaf"
                endText="Tot en met"
                value={this.state.date}
                cancelText="Annuleren"
                clearable={true}
                clearText="Legen"
                toolbarTitle="Selecteer een datumbereik"
                mask="__-__-____"
                onChange={(value) => this.changeRange(value)}
                renderInput={(startProps, endProps) => (
                <React.Fragment>
                    <TextField sx={{ my: 2, width: 300 }} {...startProps} />
                    <Box sx={{ mx: 2 }}> t/m  </Box>
                    <TextField sx={{ my: 2, width: 300 }} {...endProps} />
                </React.Fragment>
                )}
            />
            </LocalizationProvider>
        )
    }
}
export default DateRangePickerInp