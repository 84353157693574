import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as Request from './Call.js';
import Cookies from 'js-cookie';

class Login extends React.Component {
    
    constructor(props){
        
        super(props);

        this.isLoggedIn();

        this.state = {
            errorMsg: ''
        };

        this.login = this.login.bind(this);
    }

    isLoggedIn(){
        
        if(Cookies.get('authToken')){
            this.props.redirectLogin()
        }
    }

    setAuthorizations = (authorizations) => {
        localStorage.setItem('authorizations', authorizations);
    }

    login(e) {
        e.preventDefault();
        const loginData = new FormData(e.currentTarget);
        const login = {username: loginData.get('username'), password: loginData.get('password'), remember: loginData.get('remember')};
        const self = this;

        Request.Call('/Authenticate/Login', 'post', login, function(response){            

            if(response === undefined){
                return false
            }
            
            // handle errors
            if(response.status === 500){
                self.setState({ errorMsg: response.data.msg })
                return false;
            }

            self.setState({ errorMsg: '' })

            if(loginData.get('remember')){
                Cookies.set('authToken', response.data.token, { expires: 365 })
            }else{
                Cookies.set('authToken', response.data.token, { expires: 1 })
            }

            self.setAuthorizations(response.data.Autorizations);

            self.props.redirectLogin();                    
        })
    }

    render(){
        return (
            <div className="login">
                <div className="logo"></div>
                <h1>Login</h1>
                <div className="errorReport">{this.state.errorMsg}</div>
                <div className="inner">
                    <Box className="form" onSubmit={this.login} component="form" noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Gebruikersnaam"
                            name="username"
                            autoComplete="username"
                            autoFocus
                            />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Wachtwoord"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            />
                        <FormControlLabel
                            control={<Checkbox value="remember" name="remember" color="primary" />}
                            label="Onthoud mij"
                            />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Login</Button>
                    </Box>
                </div>
            </div>
        )
    }
}

export default Login;