import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { useHistory, useLocation } from "react-router-dom";

const Breadcrumb = (props) => {

    let history = useHistory();
    const location = useLocation();

    const redirectToHome = () => {

        const paths = location.pathname.split('/');
        let path = '';
        
        paths.map((pathName, key) => {
            if(paths.length > (key+1)){
                if(pathName){
                    path += pathName;
                }else{
                    path += '/';
                }
            }
            return false;
        });

        if(history.location.state && history.location.state.from){
            history.push(history.location.state.from);
        }else{
            history.push(path);
        }
    }

    return (
        <div className="breadcrumb">
            <div onClick={redirectToHome}>
                <FontAwesomeIcon size="lg" className="tileIcon" icon={props.icon} />           
                <label>{props.title}</label>
            </div>
        </div>
    )
}

export default Breadcrumb