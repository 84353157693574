import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 

class Title extends React.Component {
    
    constructor(props){

        super(props);

        this.state = {
            title: props.title,
            subTitle: props.subTitle,
            icon : props.icon,
            iconSize : props.iconSize,
            size: (props.size) ? `h${props.size}` : `h1`,
            margin: props.margin,
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props !== nextProps) {
          this.setState({
            title: nextProps.title
          });
        }
      }
    render() {
        
        const Size = this.state.size
        let Icon = null;

        if(this.state.icon){
            Icon = <div className="outer">
                        <FontAwesomeIcon className="titleIcon" icon={this.state.icon} size={this.state.iconSize}/>
                    </div>
        }

        return ( 
            <div className="title">
                {Icon}
                <div className="innerTitle">
                    <Size>{this.state.title}</Size>
                    <label className="subTitle">{this.state.subTitle}</label>
                </div>                
            </div>
        )
    }

}

export default Title