import Cookies from 'js-cookie';
import Activities from './Activities';
import * as Request from "../Call";

export const AuthUser = (route) => {
    let activities = Activities();
    if(!Cookies.get('authToken')){
        localStorage.clear();
        return false
    }
    
    if(route){
        switch(route)
        {
            default:
                return true;
            break;

            case "users":
                return (activities.edit_users);
            break;

            case "facturatie":
                return (activities.facturatie);
            break;

            case "checklist":
                return (activities.checklist);
            break;

            case "allChecklists":
                return (activities.checklist_all);
            break;

            case "allWerkbonnen":
                return (activities.werkbonnen_all);
            break;

            case "editPlanning":
                return (activities.post_afspraak);
            break;

            case "logging":
                return (activities.logging);
            break;
        }
    }

    return true
}

export const getWerknemers = () => {

    const werknemers = [];
    const url = '/Gebruikers/Overview';

    Request.Call(url, 'get', null, function(response){
        
        response.data.map((val) => {
            werknemers.push({value: val.id, label: val.firstname + ' ' + val.lastname});
        });
    });

    return werknemers;
}