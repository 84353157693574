
import React from "react";
import Breadcrumb from "../../components/Menu/Breadcrumb";
import Title from "../../components/Title";
import * as Request from "../../components/Call";
import WerkbonForm from "./Form";
import history from '../../components/history';
import WerkbonLogging from './Logging';
import * as Auth from '../../components/Auth/Auth';

class Werkbon extends React.Component {
    
    constructor(props){
        
        super(props);

        const type = (props.match) ? 'edit' : 'add';
        const werkbonId = (props.match) ? props.match.params.werkbonId : null

        this.state = {
            title: props.title,
            type: type,
            data: {opdrachtgever: '', huisnummer: '', plaats: '', straat: '', datum: '', uren: '', werkomschrijving: '', materiaal: []},
            werkbonId: werkbonId
        };    
    }

    componentDidMount(prevProps, prevState) {
        switch(this.state.type){
            case "edit":
                this.getWerkbonById(this.state.werkbonId)
            break;

            default:
                this.getMateriaal()
            break;
        } 
    }

    getWerkbonById = (id) => {

        const self = this;
        
        Request.Call('/Werkbonnen/' + id, 'get', null, function(response){
    
            if(response === undefined){
                return false
            }

            if(response.data.msg){
                history.push('/Werkbonnen');
                return false;
            }

            self.setState({ data: response.data });
        });
    }

    getMateriaal = () => {

        const self = this;
        
        Request.Call('/Materiaal', 'get', null, function(response){
    
            if(response === undefined){
                return false
            }

            self.setState({ data: response.data });
        });
    }

    render(){
        return (
            <div className="overzicht">   
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <Title title={this.state.title} size="3" ></Title>
                <WerkbonForm type={this.type} data={this.state.data} ></WerkbonForm>

                {
                    Auth.AuthUser('logging') && <WerkbonLogging type={this.state.type} werkbonId={this.state.werkbonId}></WerkbonLogging>
                }
                
            </div>
        )
    }
}

export default Werkbon