
import React from "react";
import { TextField, Button, Autocomplete, createFilterOptions } from "@mui/material";
import Box from '@mui/material/Box';
import { MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { nl } from "date-fns/locale";
import { format } from 'date-fns'
import * as Request from "../../components/Call";
import history from '../../components/history';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import Upload from "../../components/Inputs/Upload";
import { toast } from 'react-toastify';

class WerkbonForm extends React.Component {

    constructor(props){
        
        super(props);

        let dateString = (props.data.datum) ? props.data.datum : format(new Date(), 'yyyy-MM-dd');
        const materiaal = props.data.materiaal;

        if(localStorage.createWerkbon){
            let data = JSON.parse(localStorage.createWerkbon);
            dateString = format(new Date(data.datum), 'yyyy-MM-dd');
            props.data.opdrachtgever = data.opdrachtgever;
            props.data.straat = data.straat;
            props.data.huisnummer = data.huisnummer;
            props.data.plaats = data.plaats;
            
            localStorage.removeItem('createWerkbon');
        }

        this.state = {
            opdrachtgever: props.data.opdrachtgever,
            straat: props.data.straat,
            huisnummer: props.data.huisnummer,
            plaats: props.data.plaats,
            uren: props.data.uren,
            werkomschrijving: props.data.werkomschrijving,
            materiaal: materiaal,
            datum: dateString,
            files: [],
            oldFiles: (props.data.oldFiles) ? props.data.oldFiles : [],
            openMateriaal: false,
            snackbar: {status: false, msg: ''},
            errors: [],
            error: false
        };   
        
        this.defaultValues = {
            datum: format(new Date(), 'yyyy-MM-dd')
        }

        this.defaultFilterOptions = createFilterOptions();
    }

    componentWillUpdate = (prevProps, prevState) => {
        for (const property in prevProps.data) {
            if(this.props.data[property] !== prevProps.data[property]){
                const value = this.setDefaultValue(property, prevProps.data[property])
                this.setState({[property]: value});
            }
        }
    }

    setDefaultValue = (field, value) => {

        if(!value){
            if(this.defaultValues[field]){
                return this.defaultValues[field];
            }
        }

        return value;
    }

    handleChange = (event) => {
        let value = event.target.value;
        this.setState({ [event.target.name]: value });
    }

    changeDate = (date) => {
        return false;
    }

    getSelectedMateriaal = () => {
        let selectedMateriaal = [];
        for (const key in this.state.materiaal) {
            if(this.state.materiaal[key]['selected']){
                selectedMateriaal.push(this.state.materiaal[key]);
            }
        }
        
        return selectedMateriaal;
    }

    showError = (field) => {
        if(this.state.errors.includes(field)){
            return true;
        }
    }

    handleResponse = (response) => {
        const self = this;

        if(response.status === 200) {
            toast.success('Werkbon is opgeslagen', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            history.push('/Werkbonnen');
        }

        if(response.data.fields){
            this.setState({errors: response.data.fields, error: true});
        }
    }

    saveWerkbon = (e) => {
        
        e.preventDefault();

        const selectedMateriaal = this.getSelectedMateriaal();       
        
        const postBody = {id: this.state.id, straat: this.state.straat, huisnummer: this.state.huisnummer, plaats: this.state.plaats, datum: format(new Date(this.state.datum), 'yyyy-MM-dd', {locale: nl}), uren: this.state.uren, werkomschrijving: this.state.werkomschrijving, opdrachtgever: this.state.opdrachtgever, materiaal: selectedMateriaal, oldFiles: this.state.oldFiles};

        let prevSave = false;

        if(this.state.oldFiles.length < 2){
            this.state.oldFiles.map((file, rowIndex) => {
                if(file.hasOwnProperty('deleted')){
                    prevSave = true;
                }
            });
        }       
        
        if(this.state.id){
            
            if(this.state.files.length < 1 && prevSave){
                toast.error('Werkbon niet opgeslagen, voeg een bestand toe!', {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }

            Request.Call('/Werkbonnen/' + this.state.id + '/edit', 'post', postBody, this.handleResponse, this.state.files);
        }else{
            if(this.state.files.length < 1){
                toast.error('Werkbon niet opgeslagen, voeg een bestand toe!', {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                return;
            }
            Request.Call('/Werkbonnen', 'post', postBody, this.handleResponse, this.state.files);
        }        
    }

    chooseDate = (date) => {
        this.setState({datum: date});
    }

    removeMateriaal = (key) => {
        
        let materiaalArray = this.state.materiaal;
        
        if (key !== -1) {
            if(materiaalArray[key]){
                materiaalArray[key]['selected'] = false;
                //materiaalArray.sort().reverse();
                this.setState({materiaal: materiaalArray});
            }
        }        
    }

    filterOptions = (options, state) => {
        return this.defaultFilterOptions(options, state).slice(0, 25);
    }

    setOpenMateriaal = (open) => {
        this.setState({openMateriaal: open});
    }

    openMateriaalBox = (event, inputVal) => {
        this.setOpenMateriaal(inputVal.length > 0);
    }

    addMateriaal = (event, val) => {
        
        if(!val) return;

        const materiaal = this.state.materiaal;
        const materiaalCurrent = materiaal.find(mat => mat.id === val.id);
        const materiaalKey = materiaal.findIndex(mat => mat.id === val.id);

        if(materiaalCurrent){
            materiaal.splice(materiaalKey, 1);
            materiaalCurrent.hoeveelheid = 1;
            materiaalCurrent.selected = true;
            materiaal.splice(0, 0, materiaalCurrent);


            this.setState({ materiaal: materiaal });
        }

        this.setOpenMateriaal(false);
    }

    addCustomMateriaal = (value) => { 

        let artikelNr = value.split(' - ')[0];
        let counter = 0;

        const existingMateriaalKey = this.state.materiaal.findIndex(mat => (mat.artikelnr === artikelNr || mat.omschrijving === artikelNr));
        const existingNewMateriaal = this.state.materiaal.map((mat) => {
            if(mat.id.includes('new')){
                counter = (counter+1);
            }
        });

        let materiaal = this.state.materiaal;

        if(existingMateriaalKey > -1 && !materiaal[existingMateriaalKey]['selected']){
            materiaal[existingMateriaalKey]['selected'] = true;
            materiaal[existingMateriaalKey]['hoeveelheid'] = 1;
        }else if(existingMateriaalKey === -1){
            materiaal.splice(0, 0, {id: 'new-' + counter, artikelnr: null, omschrijving: value, actief: 1, selected: true, hoeveelheid: 1});
        }
        this.setState({ materiaal: materiaal });
        this.setOpenMateriaal(false);
    }

    setMateriaalHoeveelheid = (e, materiaal) => {

        let materiaalArray = this.state.materiaal;

        const materiaalIdx = materiaalArray.findIndex(mat => mat.id === materiaal.id);

        if(materiaalArray[materiaalIdx]){
            materiaalArray[materiaalIdx]['hoeveelheid'] = e.target.value;
            this.setState({materiaal: materiaalArray});
        }
    }

    getUnselectedMateriaal = () => {

        let unselectedMateriaal =[];

        for (const key in this.state.materiaal) {
            if(!this.state.materiaal[key]['selected']){
                unselectedMateriaal.push(this.state.materiaal[key]);
            }
        }

        return unselectedMateriaal;
    }

    render(){
        return (
            <div>   
                <Box className="details" noValidate sx={{ mt: 1 }}>        
                    <LocalizationProvider locale={nl} dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                            label="Datum"
                            value={this.state.datum}
                            cancelText="Annuleren"
                            mask="__-__-____"
                            onAccept={this.chooseDate}
                            renderInput={(params) => <TextField margin="normal" fullWidth variant="filled" {...params} />}
                            onChange={this.changeDate}
                        />                 
                    </LocalizationProvider>        
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="opdrachtgever"
                        label="Opdrachtgever"
                        type="text"
                        variant="filled" 
                        value={this.state.opdrachtgever}
                        onChange={(e) => this.handleChange(e)}
                        error={this.showError('opdrachtgever')}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="straat"
                        label="Straat"
                        type="text"
                        variant="filled" 
                        value={this.state.straat}
                        onChange={(e) => this.handleChange(e)}
                        error={this.showError('straat')}
                        />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="huisnummer"
                        label="Huisnummer"
                        type="text"
                        variant="filled" 
                        value={this.state.huisnummer}
                        onChange={(e) => this.handleChange(e)}
                        error={this.showError('huisnummer')}
                        />   
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="plaats"
                        label="Plaats"
                        type="text"
                        variant="filled" 
                        value={this.state.plaats}
                        onChange={(e) => this.handleChange(e)}
                        error={this.showError('plaats')}
                        /> 
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="uren"
                        label="Uren"
                        type="number"
                        variant="filled" 
                        InputProps={{ inputProps: { min: 0, max: 24, step: "0.5" } }}
                        value={this.state.uren}
                        onChange={(e) => this.handleChange(e)}
                        error={this.showError('uren')}
                        /> 

                    <Autocomplete
                        noOptionsText={'Materiaal niet gevonden.'}
                        filterOptions={this.filterOptions}
                        disableCloseOnSelect
                        freeSolo 
                        margin="normal"
                        disablePortal={true}
                        options={this.getUnselectedMateriaal()}
                        fullWidth
                        open={this.state.openMateriaal}
                        onInputChange={this.openMateriaalBox}
                        value={null}
                        getOptionLabel={(option) => {
                                                        if(option.artikelnr && option.omschrijving){
                                                            return option.artikelnr + ' - ' + option.omschrijving;
                                                        }else if(option.omschrijving){
                                                            return option.omschrijving;
                                                        }else{
                                                            return option.artikelNr + ' - ' + option.omschrijving;
                                                        }
                                                    }
                        }
                        renderInput={(params) => <TextField 
                                                    onKeyDown={e => {
                                                                if ((e.key === 'Enter' || e.which === 13) && e.target.value) {
                                                                    e.preventDefault();
                                                                    this.addCustomMateriaal(e.target.value);
                                                                    e.target.value = '';
                                                                }
                                                            }}
                                                    margin="normal"
                                                    variant="filled" 
                                                    {...params} 
                                                    label="Materiaal toevoegen.." />}
                        onChange={(event, value) => this.addMateriaal(event, value)}
                        />

                    <table border="1" className="selectedMateriaal">
                        <tbody>
                            {
                                this.state.materiaal && this.state.materiaal.map((mat, key) => {
                                    if(mat.selected){
                                        return <tr key={key}>
                                                <td>{mat.artikelnr} {mat.artikelnr ? '-' : ''} {mat.omschrijving}</td>
                                                <td>
                                                    <TextField className="small-textField" margin="normal" value={mat.hoeveelheid} variant="filled" label="Hoeveelheid" onChange={(e) => this.setMateriaalHoeveelheid(e, mat)} />
                                                    <FontAwesomeIcon className="deleteIcon" onClick={() => { this.removeMateriaal(key) }} icon="times" />
                                                </td>
                                            </tr>;
                                    }
                                })
                            }
                        </tbody>
                    </table>

                    <Upload name="bestanden" label="Bestanden toevoegen.. *" oldFiles={this.state.oldFiles} files={this.state.files} />

                    <TextField
                        margin="normal"
                        rows={4} 
                        variant="filled"
                        fullWidth
                        name="werkomschrijving"
                        label="Werkomschrijving"
                        onChange={(e) => this.handleChange(e)}
                        multiline   
                        value={this.state.werkomschrijving}
                        error={this.showError('werkomschrijving')}
                        />
                    
                    <Button type="submit" onClick={e => {this.saveWerkbon(e)}} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Opslaan</Button>
                </Box>
            </div>
        )
    }
}

export default WerkbonForm