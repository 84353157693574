import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import React from 'react';
import debounce from 'lodash.debounce';
import Filters from "./Filters"
import { Checkbox } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomDialog from '../Dialog';

class MobileOVerview extends React.Component {

    constructor(props){
        
        super(props);

        const checkboxes = (props.checkboxes) ? props.checkboxes : false;

        this.state = {
            data: props.data,
            rowClick: props.rowClick,
            iconClick: props.iconClick,
            deleteRow: props.deleteRow,
            scrollTop: 0,
            loading: props.loading,
            filters: props.filters,
            name: props.name,
            checkboxes: checkboxes,
            handleFilter: this.handleParentFilter,
            total: props.total,
            deleteId: 0,
            deleteDialog: false,
            length: 0,
            overviewType: props.type
        };

        this.loadMoreData = debounce(this.loadMoreData.bind(this), 400, {'leading': true});
    }    

    getDifference = (prevProps) => {

        const results = prevProps.data.filter(({ id: id1 }) => !this.props.data.some(({ id: id2 }) => id2 === id1));

        if(results.length > 0){
            return true;
        }

        if(prevProps.data.length !== this.props.data.length){
            return true;
        }

        return false;
    }

    componentWillUpdate = (prevProps, prevState) => {

        if(this.getDifference(prevProps)){
            this.setState({data: prevProps.data});
        }

        if(prevProps.loading !== this.state.loading){
            this.setState({loading: prevProps.loading});
        }

        if(prevState.deleteDialog !== this.state.deleteDialog){
            //this.setState({deleteDialog: prevState.deleteDialog});
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.loadMoreData);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.loadMoreData);
    }

    loadMoreData = () => {
        const {
            scrollTop,
            scrollHeight,
            clientHeight
        } = document.documentElement;

        if(this.state.length === this.state.data.length) return;
        
        if( (scrollTop + clientHeight) > (scrollHeight - 100)){
            
            if(this.props.pageLoad){
                this.props.pageLoad(this.state.filters, (this.props.page+1));
                this.setState({length: this.state.data.length});
            }
        }
    }

    clickedRow = (e, id) => {        
        if(e.target.nodeName === 'DIV'){            
            localStorage.setItem("offsetRow", e.target.offsetTop)
            this.state.rowClick(id);
        }
    }

    handleParentFilter = (filters, page) => {
        this.setState({filters: filters});
        this.props.handleFilter(filters, page);
        this.selectAll(true);
    }

    checkBox = (e, item) => {
        const checkboxes = this.state.checkboxes;
        
        if(checkboxes.items.includes(item[checkboxes.key])){
            checkboxes.items.splice(checkboxes.items.findIndex((checkboxId) => checkboxId === item[checkboxes.key]), 1)
        }else{
            checkboxes['items'].push(item[checkboxes.key]);
        }

        this.setState({checkboxes: checkboxes});
        checkboxes.check();
    }

    selectAll = (deselect) => {

        const checkboxes = this.state.checkboxes;
        if(!checkboxes) return false;

        if(deselect === false){
            if(checkboxes['items'].length > 0){
                checkboxes['items'] = [];
            }else{
                this.state.data.map((item) => {
                    checkboxes['items'].push(item[checkboxes.key]);            
                });
            }
        }else{
            checkboxes['items'] = [];
        }

        this.setState({checkboxes: checkboxes});
        checkboxes.check();
    }

    deleteRow = (e, id) => {       
        if(e.target.nodeName === 'svg' || e.target.nodeName === 'path'){
            this.setState({deleteId: id, deleteDialog: true});
        }
    }

    deleteRowConfirmed = () => {
        this.setState({deleteDialog: false});
        this.state.deleteRow(this.state.deleteId);
    }

    clickIconRight = (event, itemId) => {
        this.state.iconClick(itemId);
    }

    letterToUpper = (string) => {

        if(!string) return;
        
        const firstLetter = string.charAt(0);
        
        return firstLetter.toUpperCase() + string.slice(1);
    }
    
    render(){

        return (
            <div className="mobileOverview"> 
                <CustomDialog open={this.state.deleteDialog} title={this.letterToUpper(this.state.overviewType) + " verwijderen"} content={"Weet je zeker dat je deze " + this.state.overviewType + " wilt verwijderen?"} buttons={[{title: 'Ja', onClick: this.deleteRowConfirmed}, {title: 'Nee'}]}/>
                {
                    this.state.filters && 
                         <Filters name={this.state.name} onChange={this.state.handleFilter} filters={this.state.filters}></Filters>
                }
                {
                    this.state.checkboxes &&
                    <div className="selectAll">
                        <FormControlLabel
                            name="selectAll"
                            control={<Checkbox name="selectAll" onChange={(e) => this.selectAll(false)} checked={(this.state.checkboxes.items.length > 0)}/>}
                            label={(this.state.checkboxes.items.length > 0) ? "De-selecteer alles" : "Selecteer alles"}
                            labelPlacement="end"
                        />
                    </div>
                }

                {this.state.total &&
                    <label className="total">
                        {this.state.total.value}
                        {this.state.total.icon &&
                            <FontAwesomeIcon className="icon-right" icon={this.state.total.icon}/>
                        }
                    </label>
                }
                {
                    this.state.data.map((item, key) => {
                        return <div className="item" onClick={(e) => { this.clickedRow(e, item['id']) }} key={key}>
                            {
                                this.state.checkboxes &&
                                <Checkbox checked={this.state.checkboxes.items.includes(item[this.state.checkboxes.key])} onChange={(e) => this.checkBox(e, item)}/>
                            }

                            <div className="titles">
                                <div className="title">{item.title}</div>
                                <div className="subTitle">{item.subTitle}</div>
                            </div>
                            { (item.titleRight) && 
                                <div className="title-right">{item.titleRight} <FontAwesomeIcon className="icon-right" size={item.titleRightIconSize} icon={item.titleRightIcon.icon}/></div>
                            }
                            { (item.titleRightIcon && !item.titleRight) &&
                                <div className="title-right"><FontAwesomeIcon onClick={(e) => this.clickIconRight(e, item['id'])} className="icon-right" size={item.titleRightIconSize} icon={item.titleRightIcon.icon}/></div>
                            }
                            { (this.state.deleteRow) &&
                                <div className="title-right"><FontAwesomeIcon className="icon-right" icon='trash' size="lg" onClick={(e) => this.deleteRow(e, item['id'])}/></div>
                            }                            
                        </div>
                    })
                }
                {
                    this.state.loading &&
                    <FontAwesomeIcon className="spinner" icon="spinner" size="3x" spin />
                }
            </div>
        )
    }
}

export default MobileOVerview