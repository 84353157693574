import * as React from 'react';
import {Route, Redirect } from 'react-router-dom'
import * as Auth from '../components/Auth/Auth';

class PrivateRoute extends React.Component {

    constructor(props){
      super(props);
    }
    
    render(){
        return (
          <div className="content">
            {
              (Auth.AuthUser(this.props.auth) === false) ? 
                <Redirect to='/' />
                :
                <Route {...this.props} render={({ location }) => { return this.props.children }} />
            }
            </div>
        )
    }
}
export default PrivateRoute;
  