import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { useHistory } from "react-router-dom";

const Tile = (props) => {

    let history = useHistory();

    const redirectToUrl = () => {
        history.push(props.url)
    }

    return (
        <div className="tile" onClick={redirectToUrl}>
            <FontAwesomeIcon size="6x" className="tileIcon" icon={props.icon} />           
            <label>{props.title}</label>
        </div>
    )
}

export default Tile