import React from "react";
import Breadcrumb from "../../components/Menu/Breadcrumb";
import Title from "../../components/Title";
import { Box } from "@mui/system";
import { TextField, Button } from "@mui/material";
import { MobileDatePicker, MobileTimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { nl } from "date-fns/locale";
import { format } from 'date-fns'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Upload from "../../components/Inputs/Upload";
import * as Request from "../../components/Call";
import history from "../../components/history";
import * as Auth from '../../components/Auth/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome' 
import { toast } from 'react-toastify';

class Afspraak extends React.Component {

    constructor(props){

        super(props);

        this.state = this.getInitialState(props);

        if(this.hasId()){
            this.getAfspraakById();
        }
    }

    hasId = () => {

        let id;

        if(this.props.match){
            if(!this.props.match.params.time.includes(':')){
                id = this.props.match.params.time;
            }
        }

        return id;
    }

    getInitialState = (props) => {

        let [defaultStartTime, defaultEndTime] = format(new Date(), 'yyyy-MM-dd HH:mm', { locale: nl });
        let id = this.hasId(props);

        if(this.props.match){
            if(this.props.match.params.time.includes(':')){
                defaultStartTime = format(new Date(), 'yyyy-MM-dd ', { locale: nl }) + this.props.match.params.time;
            }
        }

        return {
            id: id,
            opdrachtgever: '',
            straat: '',
            huisnummer: '',
            plaats: '',
            datum: format(new Date(), 'yyyy-MM-dd'),
            startTijd: defaultStartTime,
            eindTijd: defaultEndTime,
            werkomschrijving: '',
            telefoonnr: '',
            gebruikers: [],
            selectedUsers: [],
            files: [],
            oldFiles: (props.data && props.data.oldFiles) ? props.data.oldFiles : [],
            errors: [],
            reset: false,
            canEdit: (Auth.AuthUser('editPlanning'))
        }
    }

    componentDidMount() {
        this.selectCollegas();
    }

    selectCollegas = () => {

        const self = this;

        Request.Call('/Gebruikers/Overview', 'get', null, function(response){

            const users = [];

            response.data.map((val) => {
                users.push({username: val.username, name: val.firstname + ' ' + val.lastname})
            });

            self.setState({gebruikers: users});
        });
    }

    showError = (field) => {
        if(this.state.errors.includes(field)){
            return true;
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    changeTime = (e, type) => {
        this.setState({ [type]: e });
    }

    handleUsers = (e) => {
        this.setState({ selectedUsers: e.target.value });
    }

    getAfspraakById(){

        const self = this;

        Request.Call('/Planning/Afspraken/' + this.state.id, 'get', null, (resp) => {
            if(resp.status !== 200){
                console.error('oops');
            }

            resp = resp.data;

            self.setState({
                datum: format(new Date(resp.datum), 'yyyy-MM-dd'),
                startTijd: new Date(resp.startTijd),
                eindTijd: new Date(resp.eindTijd),
                opdrachtgever: resp.opdrachtgever,
                straat: resp.straat,
                huisnummer: resp.huisnummer,
                plaats: resp.plaats,
                telefoonnr: resp.telefoon,
                selectedUsers: resp.medewerkers.split(','),
                werkomschrijving: resp.werkomschrijving,
                oldFiles: resp.oldFiles,
            });
        }, this.state.files);
    }

    saveAfspraak = (e, reset = false) => {

        e.preventDefault();

        this.setState({reset: reset});
       
        const postBody = {id: this.state.id, straat: this.state.straat, huisnummer: this.state.huisnummer, plaats: this.state.plaats, datum: format(new Date(this.state.datum), 'yyyy-MM-dd', {locale: nl}), werkomschrijving: this.state.werkomschrijving, telefoonnr: this.state.telefoonnr, opdrachtgever: this.state.opdrachtgever, oldFiles: this.state.oldFiles, werknemers: this.state.selectedUsers, startTijd: format(new Date(this.state.startTijd), 'HH:mm', {locale: nl}), eindTijd: format(new Date(this.state.eindTijd), 'HH:mm', {locale: nl})};

        console.log(postBody)
        
        if(this.state.id){
            Request.Call('/Planning/Afspraken/' + this.state.id + '/edit', 'post', postBody, this.handleResponse, this.state.files);
        }else{
            Request.Call('/Planning', 'post', postBody, this.handleResponse, this.state.files);
        }   
    }

    handleResponse = (response) => {
        const self = this;

        if(response.status === 200) {
            
            toast.success('Afspraak is aangemaakt.', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            if(self.state.reset === true){
                self.resetFields();
            }else{
                history.push('/Planning');
            }
        }

        if(response.data.fields){
            this.setState({errors: response.data.fields, error: true});
        }
    }

    resetFields = () => {
        this.setState(this.getInitialState(this.props));
    }

    createWerkbon = () => {
        localStorage.setItem('createWerkbon', JSON.stringify({datum: this.state.datum, opdrachtgever: this.state.opdrachtgever, straat: this.state.straat, huisnummer: this.state.huisnummer, plaats: this.state.plaats}));
        history.push('/Werkbonnen/Nieuw');
    }

    createTaak = () => {
        localStorage.setItem('createTaak', JSON.stringify({datum: this.state.datum, opdrachtgever: this.state.opdrachtgever, straat: this.state.straat, huisnummer: this.state.huisnummer, plaats: this.state.plaats}));
        history.push('/Taken/Nieuw');
    }

    createChecklistWoning = () => {
        localStorage.setItem('createChecklistWoning', JSON.stringify({datum: this.state.datum, opdrachtgever: this.state.opdrachtgever, straat: this.state.straat, huisnummer: this.state.huisnummer, plaats: this.state.plaats}));
        history.push('/Checklist-woningen/Nieuw');
    }
 
    render(){

        return (
            <div className="overzicht">
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    {this.state.canEdit &&
                        <Title title="Nieuwe afspraak aanmaken" size="3"></Title>
                    }
                </div>

                <Box className="details" noValidate  sx={{ mt: 1, maxWidth: 800}}>
                    <div className="dateTime">
                        <LocalizationProvider locale={nl} dateAdapter={AdapterDateFns}>
                                <MobileDatePicker
                                    label="Datum"
                                    readOnly={(!this.state.canEdit)}
                                    value={this.state.datum}
                                    cancelText="Annuleren"
                                    mask="__-__-____"
                                    onAccept={this.chooseDate}
                                    required
                                    renderInput={(params) => <TextField required error={this.showError('datum')} margin="normal" variant="filled" {...params} />}
                                    onChange={(e) => this.changeTime(e, 'datum')}
                                />  
                                <MobileTimePicker
                                    label="Start tijd"
                                    readOnly={(!this.state.canEdit)}
                                    value={this.state.startTijd}
                                    onChange={(e) => this.changeTime(e, 'startTijd')}
                                    renderInput={(params) => <TextField error={this.showError('startTijd')} margin="normal" required className="timeField" sx={{ width: '100px' }} variant="filled" {...params} />}
                                />
                                <MobileTimePicker
                                    label="Eind tijd"
                                    value={this.state.eindTijd}
                                    readOnly={(!this.state.canEdit)}
                                    onChange={(e) => this.changeTime(e, 'eindTijd')}
                                    onAccept={(e) => this.changeTime(e, 'eindTijd')}
                                    renderInput={(params) => <TextField error={this.showError('eindTijd')} margin="normal" required className="timeField" sx={{ width: '100px' }} variant="filled" {...params} />}
                                />                
                            </LocalizationProvider>
                        </div>
                    <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="opdrachtgever"
                            label="Opdrachtgever"
                            type="text"
                            variant="filled" 
                            value={this.state.opdrachtgever}
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('opdrachtgever')}
                            inputProps={
                                { readOnly: (!this.state.canEdit) }
                            }
                    />
                    <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="straat"
                            label="Straat"
                            type="text"
                            variant="filled" 
                            value={this.state.straat}
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('straat')}
                            inputProps={
                                { readOnly: (!this.state.canEdit) }
                            }
                    />
                    <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="huisnummer"
                            label="Huisnummer"
                            type="text"
                            variant="filled" 
                            value={this.state.huisnummer}
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('huisnummer')}
                            inputProps={
                                { readOnly: (!this.state.canEdit) }
                            }
                    />
                    <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="plaats"
                            label="Plaats"
                            type="text"
                            variant="filled" 
                            value={this.state.plaats}
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('plaats')}
                            inputProps={
                                { readOnly: (!this.state.canEdit) }
                            }
                    />
                    <TextField
                            margin="normal"
                            fullWidth
                            name="telefoonnr"
                            label="Telefoonnummer"
                            type="text"
                            variant="filled" 
                            value={this.state.telefoonnr}
                            onChange={(e) => this.handleChange(e)}
                            error={this.showError('telefoonnr')}
                            inputProps={
                                { readOnly: (!this.state.canEdit) }
                            }
                    />

                    <InputLabel id="medewerkers-label">Medewerkers</InputLabel>
                    <Select
                        labelId="medewerkers-label"
                        multiple
                        fullWidth
                        variant="filled"
                        value={this.state.selectedUsers}
                        onChange={this.handleUsers}
                        renderValue={(selected) => selected.join(', ')}
                        readOnly={(!this.state.canEdit)}
                        >
                        {this.state.gebruikers.map((gebruiker) => (
                            <MenuItem key={gebruiker} value={gebruiker.username}>
                            <Checkbox checked={this.state.selectedUsers.indexOf(gebruiker.username) > -1} />
                            <ListItemText primary={gebruiker.name} />
                            </MenuItem>
                        ))}
                    </Select>

                    <TextField
                        margin="normal"
                        rows={4} 
                        variant="filled"
                        fullWidth
                        name="werkomschrijving"
                        label="Werkomschrijving"
                        onChange={(e) => this.handleChange(e)}
                        multiline   
                        value={this.state.werkomschrijving}
                        error={this.showError('werkomschrijving')}
                        inputProps={
                            { readOnly: (!this.state.canEdit) }
                        }
                        />
                    <Upload readonly={(!this.state.canEdit)} name="bestanden" label="Bestanden toevoegen.." oldFiles={this.state.oldFiles} files={this.state.files} />
                    {this.state.error && <label className="error">Één of meerdere velden zijn niet ingevuld.</label>}
                    { this.state.canEdit ?
                        <div className="buttons">
                            <Button type="submit" onClick={e => {this.saveAfspraak(e)}}  variant="contained" sx={{ mr: 2, mt: 3, mb: 2 }}>Opslaan</Button>
                            <Button type="submit" onClick={e => {this.saveAfspraak(e, true)}}  variant="contained" sx={{ mt: 3, mb: 2 }}>Opslaan en nieuwe afspraak plannen</Button>
                        </div>
                        :
                        <div className="buttons">
                            <Title title="Snelkoppelingen" size="3"></Title>
                            <div className="btn" onClick={e => {this.createWerkbon(e)}}>
                                <FontAwesomeIcon className="titleIcon" icon="file-invoice" size="2x"/>
                                <label>Werkbon aanmaken</label>
                            </div>
                            
                            <div className="btn" onClick={e => {this.createTaak(e)}}>
                                <FontAwesomeIcon className="titleIcon" icon="tasks" size="2x"/>
                                <label>Taak aanmaken</label>
                            </div>
                            
                            {
                                Auth.AuthUser('checklist') &&
                                    <div className="btn" onClick={e => {this.createChecklistWoning(e)}}>
                                        <FontAwesomeIcon className="titleIcon" icon="house-user" size="2x"/>
                                        <label>Checklist woning aanmaken</label>
                                    </div>
                            }
                        </div>
                }
                </Box>
            </div>
        )
    }
}
export default Afspraak