
export const Activities = () => {

    let authObject = {};
    if(localStorage.authorizations){
        localStorage.authorizations.split(',').map((authorization) => {
            authObject[authorization] = true;
        });
    }

    return authObject;
}

export default Activities;