import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import React from "react";
import * as Request from "../../components/Call";
import { TextField, Button, Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import history from '../../components/history';
import Upload from "../../components/Inputs/Upload";
import { toast } from 'react-toastify';

class TakenForm extends React.Component {

    constructor(props){
        
        super(props);

        let straat, huisnummer, plaats;

        if(localStorage.createTaak){
            let data = JSON.parse(localStorage.createTaak);
            straat = (data.straat) ? data.straat : '';
            huisnummer = (data.huisnummer) ? data.huisnummer : '';
            plaats = (data.plaats) ? data.plaats : '';
            localStorage.removeItem('createTaak');
        }else{
            straat = '';
            huisnummer = '';
            plaats = '';
        }

        this.state = {
            straat: straat,
            huisnummer: huisnummer,
            plaats: plaats,
            omschrijving: '',
            files: [],
            oldFiles: (props.data && props.data.oldFiles) ? props.data.oldFiles : [],
            taakId: (props.match && props.match.params && props.match.params.TakenId) ? props.match.params.TakenId : false,
            errors: [],
            error: false,
        };     
    }

    componentDidMount(prevProps, prevState) {
        if(!this.state.taakId) return;
        this.getTaakById(this.state.taakId)
    }

    getTaakById = (id) => {

        const self = this;

        Request.Call('/Taken/' + id, 'get', null, function(response){

            if(response.data.length < 1) return false;

            const data = response.data;

            self.setState({straat: data.straat, huisnummer: data.huisnummer, plaats: data.plaats, omschrijving: data.omschrijving, oldFiles: data.oldFiles})
        });
    }

    afronden = (e, afgerond = false) => {
        
        e.preventDefault();
        
        const postBody = {id: this.state.taakId, straat: this.state.straat, huisnummer: this.state.huisnummer, plaats: this.state.plaats, omschrijving: this.state.omschrijving, afgerond: afgerond, oldFiles: this.state.oldFiles};
        
        if(this.state.taakId){
            Request.Call('/Taken/' + this.state.taakId + '/edit', 'post', postBody, this.handleResponse, this.state.files);
        }else{
            Request.Call('/Taken', 'post', postBody, this.handleResponse, this.state.files);
        }
    }

    handleResponse = (response) => {
        const self = this;

        if(response.status === 200) {
            toast.success('Taak is opgeslagen.');
            history.push('/Taken');
        }

        if(response.data.fields){
            this.setState({errors: response.data.fields, error: true});
        }
    }

    handleChange = (event) => {
        this.setState({[event.target.name] : event.target.value });
    }

    render(){
        return (
            <Box className="details" noValidate sx={{ mt: 1 }}>        
                <div className="overzicht">   
                    <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                    <Title title='Taak aanpassen' size="5" ></Title>
                    <div className="form-fields">
                        <div className="default-fields">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="straat"
                                label="Straat"
                                type="text"
                                variant="filled" 
                                value={this.state.straat}
                                onChange={(e) => this.handleChange(e)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="huisnummer"
                                label="Huisnummer"
                                type="text"
                                variant="filled" 
                                value={this.state.huisnummer}
                                onChange={(e) => this.handleChange(e)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="plaats"
                                label="Plaats"
                                type="text"
                                variant="filled" 
                                value={this.state.plaats}
                                onChange={(e) => this.handleChange(e)}
                            />

                            <Upload name="bestanden" label="Bestanden toevoegen.." oldFiles={this.state.oldFiles} files={this.state.files} />

                            <TextField
                                margin="normal"
                                rows={4} 
                                variant="filled"
                                fullWidth
                                name="omschrijving"
                                label="Taak omschrijving"
                                onChange={(e) => this.handleChange(e)}
                                multiline   
                                value={this.state.omschrijving}
                                />

                            {this.state.error && <label className="error">Één of meerdere velden zijn niet ingevuld.</label>}                            
                            <Button type="submit" fullWidth onClick={e => {this.afronden(e)}}  variant="contained" sx={{ mt: 3, mb: 2 }}>Opslaan</Button>

                            {this.state.taakId && 
                                <Button type="submit" fullWidth color="success" onClick={e => {this.afronden(e, true)}}  variant="contained" sx={{ mr: 2, mt: 3, mb: 2 }}>Afronden</Button>
                            }
                        </div>
                    </div>

                    
                </div>
            </Box>
        )
    }
}

export default TakenForm;