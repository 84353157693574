import * as React from 'react';
import { Router, Route, Switch} from 'react-router-dom'
import Home from "./pages/Home"
import Planning from "./pages/Planning/Index"
import Afspraak from './pages/Planning/Afspraak';
import Werkbonnen from "./pages/Werkbonnen/Index"
import Werkbon from "./pages/Werkbonnen/Werkbon"
import Overzicht from "./pages/Overzicht"
import Gebruikers from "./pages/Gebruikers/Index"
import Gebruiker from "./pages/Gebruikers/Detail"
import Materiaal from "./pages/Materiaal/Index"
import Taken from "./pages/Taken/Index"
import TakenForm from "./pages/Taken/Form"
import MateriaalDetails from "./pages/Materiaal/Detail"
import history from './components/history';
import ChecklistWoningen from './pages/Checklist/Woningen/Overview';
import ChecklistWoningForm from './pages/Checklist/Woningen/Form';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import PrivateRoute from './routes/PrivateRoute.js'
import { Logoff } from './components/Auth/Logoff';
import Facturatie from './pages/Facturatie';
import FacturatieDetails from './pages/Facturatie/Details';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { handleScroll } from './components/Scroll/Scroll.js';

class App extends React.Component {

  constructor(props){
        
    super(props);

    this.state = {
      snackbar: {status: false, msg: ''}
    };   

    library.add(fab, fas)

    handleScroll();
  }
  
  render(){

    return (
      <div className="App" >
        <Router history={history}>
          <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              
              <PrivateRoute path='/Overzicht'>
                <Overzicht />
              </PrivateRoute>

              <PrivateRoute path='/Logoff'>
                <Logoff />
              </PrivateRoute>

              <PrivateRoute exact path='/Planning'>
                <Planning />
              </PrivateRoute>

              <PrivateRoute exact path='/Planning/Nieuw'>
                <Afspraak />
              </PrivateRoute>
              <PrivateRoute exact path='/Planning/:time' children={(props) => <Afspraak {...props} />}></PrivateRoute>

              <PrivateRoute exact path='/Werkbonnen'>
                <Werkbonnen />
              </PrivateRoute>
              <PrivateRoute exact path='/Werkbonnen/:werkbonId([0-9]+)' children={(props) => <Werkbon title="Werkbon" {...props} />}></PrivateRoute>
              <PrivateRoute exact path='/Werkbonnen/Nieuw'>
                <Werkbon title="Nieuwe werkbon" />
              </PrivateRoute>

              <PrivateRoute auth="users" exact path='/Gebruikers'>
                <Gebruikers />
              </PrivateRoute>
              <PrivateRoute auth="users" exact path='/Gebruikers/:gebruikerId([0-9]+)' children={(props) => <Gebruiker {...props}/>}></PrivateRoute>
              <PrivateRoute auth="users" exact path='/Gebruikers/Nieuw'>
                <Gebruiker title="Nieuwe gebruiker" />
              </PrivateRoute>
            
              <PrivateRoute  exact path='/Materiaal'>
                <Materiaal />
              </PrivateRoute>
              <PrivateRoute exact path='/Materiaal/:materiaalId([0-9]+)' children={(props) => <MateriaalDetails {...props}/>}></PrivateRoute>
              <PrivateRoute exact path='/Materiaal/Nieuw'>
                <MateriaalDetails title="Nieuwe materiaal" />
              </PrivateRoute>    

              <PrivateRoute exact path='/Taken'>
                <Taken />
              </PrivateRoute>
              <PrivateRoute exact path='/Taken/:TakenId([0-9]+)' children={(props) => <TakenForm title="Taak" {...props}/>}></PrivateRoute>
              <PrivateRoute exact path='/Taken/Nieuw'>
                <TakenForm title="Nieuwe Taken" />
              </PrivateRoute>

              <PrivateRoute  exact path='/Checklist-woningen'>
                <ChecklistWoningen />
              </PrivateRoute>

              <PrivateRoute exact path='/Facturatie/:adresId([0-9]+)' children={(props) => <FacturatieDetails {...props}/>}></PrivateRoute>
              <PrivateRoute  exact path='/Facturatie'>
                <Facturatie />
              </PrivateRoute>

              <PrivateRoute exact path='/Checklist-woningen/:checklistId([0-9]+)' children={(props) => <ChecklistWoningForm title="Checklist woning" {...props} />}></PrivateRoute>
              <PrivateRoute exact path='/Checklist-woningen/Nieuw'>
                <ChecklistWoningForm title="Checklist woning" />
              </PrivateRoute>                
            </Switch>
          </Router>
          <ToastContainer
            position="bottom-left"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            theme="light"
          />
      </div>
    )
  }
}
export default App;
