import Login from "../components/Login.js"
import history from '../components/history';

const Home = (props) => {

    const redirectDashboard = () => {
        history.push('/Overzicht')
    }

    return (
        <div className="home">   
            <Login redirectLogin={redirectDashboard}/>
        </div>
    )
}

export default Home