import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import React from "react";
import * as Request from "../../components/Call";
import { TextField, Button, Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import history from '../../components/history';
import { toast } from 'react-toastify';

class MateriaalDetails extends React.Component {

    constructor(props){
        
        super(props);

        const materiaalId = (props.match) ? props.match.params.materiaalId : null

        this.state = {
            materiaalDetails: {artikelnr: '', omschrijving: '', actief: true},
            materiaalId: materiaalId
        };         
    }

    componentDidMount(prevProps, prevState) {
        if(!this.state.materiaalId) return;
        this.getMateriaalById(this.state.materiaalId)
    }

    getMateriaalById = (id) => {

        const self = this;

        Request.Call('/Materiaal/' + id, 'get', null, function(response){
            self.setState({materiaalDetails: response.data});
        });
    }

    handleChange = (event) => {
        switch(event.target.type){

            default:
                let value = event.target.value;
                this.setState( prevState => ({ materiaalDetails: {...prevState.materiaalDetails, [event.target.name]: value} }));
            break;

            case "checkbox":
                this.setState(prevState => ({ materiaalDetails: {...prevState.materiaalDetails, [event.target.name]: (!this.state.materiaalDetails[event.target.name])}}));
            break;
        }
    }

    saveMateriaal = (e) => {

        e.preventDefault();
        const self = this;

        if(this.state.materiaalId){
            Request.Call('/Materiaal/' + this.state.materiaalId, 'put', this.state.materiaalDetails, function(response){
                self.showMessage();
                history.push('/Materiaal');
            });
        }else{
            Request.Call('/Materiaal', 'post', this.state.materiaalDetails, function(response){
                self.showMessage();
                history.push('/Materiaal');
            });
        }
    }

    showMessage = () => {
        toast.success('Materiaal is opgeslagen.');
    }

    render(){
        return (
            <Box className="details" onSubmit={this.saveMateriaal} component="form" noValidate sx={{ mt: 1 }}>        
                <div className="overzicht">   
                    <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                    <Title title={`Materiaal aanpassen - ${this.state.materiaalDetails.artikelnr} ${this.state.materiaalDetails.omschrijving}`} size="5" ></Title> 
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="artikelnr"
                        label="Artikel nummer"
                        type="text"
                        variant="filled" 
                        value={this.state.materiaalDetails.artikelnr}
                        onChange={(e) => this.handleChange(e)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="omschrijving"
                        label="Omschrijving"
                        type="text"
                        variant="filled" 
                        value={this.state.materiaalDetails.omschrijving}
                        onChange={(e) => this.handleChange(e)}
                    />
                    <FormControlLabel
                        name="actief"
                        control={<Checkbox name="actief" onChange={(e) => this.handleChange(e)} checked={this.state.materiaalDetails.actief}/>}
                        label="Actief"
                        labelPlacement="end"
                    />
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Opslaan</Button>
                </div>
            </Box>
        )
    }
}

export default MateriaalDetails;



