import React from "react";
import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Calendar from "../../components/Calendar/Calendar";
import * as Request from "../../components/Call";
import { AuthUser } from "../../components/Auth/Auth";
import history from "../../components/history";
import formatDate from "../../components/Calendar/formatDate";
import { format, eachDayOfInterval , startOfWeek, endOfWeek, add, subWeeks, subDays} from 'date-fns'
import { Button } from "@mui/material";
import MobileCalendar from "../../components/Calendar/MobileCalendar";
import { isMobile } from "../../components/Mobile";
import * as Auth from '../../components/Auth/Auth';
import { toast } from 'react-toastify';

class Planning extends React.Component {

    constructor(props){

        super(props);
        
        this.state = {
            dates: this.setDates(),
            users: null,
            calendar: []
        }
    }

    getStartEndWeekdays = (startOfWeekDate = null, endOfWeekDate = null) => {

        startOfWeekDate = (!startOfWeekDate) ? startOfWeek(new Date(), {weekStartsOn: 1}) : startOfWeekDate;
        endOfWeekDate = (!endOfWeekDate) ? endOfWeek(new Date(), {weekStartsOn: 1}) : endOfWeekDate;

        let daysOfWeek = eachDayOfInterval ({
            start: startOfWeekDate,
            end: endOfWeekDate
        });

        daysOfWeek.pop();

        return {startDate: formatDate(daysOfWeek[0], 'yyyy-MM-dd'), endDate: formatDate(daysOfWeek[(daysOfWeek.length-1)], 'yyyy-MM-dd')};
    }

    setDates = (startDate = null, endDate = null) => {

        if(!startDate){
            startDate = formatDate(new Date(), 'yyyy-MM-dd');
        }
        
        if(!endDate){
            endDate = formatDate(new Date(), 'yyyy-MM-dd');
        }

        if(!isMobile()){
            let weekDays = this.getStartEndWeekdays();            
            startDate = weekDays.startDate;
            endDate = weekDays.endDate;
        }

        return [startDate, endDate];
    }

    componentDidMount() {
        this.selectAfspraken();
    }

    selectAfspraken = (newDates = false) => {

        const self = this;
        let url;

        if(newDates === false){

            let startDate = this.state.dates[0];
            let endDate = this.state.dates[1];

            if(localStorage.getItem('planningDates')){
                startDate = localStorage.getItem('planningDates').split(',')[0];
                endDate = localStorage.getItem('planningDates').split(',')[1];
                this.setState({dates: [startDate, endDate]});
            }

            url = '/Planning/Afspraken/'+startDate+'/'+endDate;
        }else{
            url = '/Planning/Afspraken/'+newDates[0]+'/'+newDates[1];
        }
        
        Request.Call(url, 'get', null, function(response){

            const afspraken = [];

            response.data.map((val) => {
                afspraken.push({id: val.id, title: val.straat + ' ' + val.huisnummer, subTitle: val.plaats, date: val.datum, startTime: val.startTijd, endTime: val.eindTijd, gebruikers: val.medewerkers, maps: val.maps, callCustomer: val.telefoon, selected: val.selected, opdrachtgever: val.opdrachtgever, werkomschrijving: val.werkomschrijving, hasTask: val.hasTask})
            });

            self.setState({calendar: afspraken})
        });
    }

    addAfspraak = (time) => {
        if(typeof time === 'string'){
            history.push('/Planning/' + time);
        }else{
            history.push('/Planning/Nieuw');
        }
    }

    updateAfspraak = (id, date) => {

        const self = this;

        Request.Call('/Planning/Afspraken/'+id+'/date', 'put', {date: formatDate(date, 'yyyy-MM-dd')}, (response) => {
            toast.success('Afspraak is verplaatst.');
            self.selectAfspraken();
        });
    }

    setDateFilter = (type) => {

        const today = format(new Date(), 'yyyy-MM-dd')
        let newDates = [today, today];
        let startDate;
        let endDate;

        switch(type){
            default:
            case "today":
                this.setState({dates: newDates})
            break;

            case "yesterday":
                let yesterday = format(subDays(new Date(this.state.dates[0]), 1), 'yyyy-MM-dd');;

                if(format(new Date(yesterday), 'i') === '7'){
                    yesterday = format(subDays(new Date(this.state.dates[1]), 2), 'yyyy-MM-dd');
                }

                newDates = [yesterday, yesterday];

                this.setState({dates: newDates})
            break

            case "tomorrow":
                let tomorrow = format(add(new Date(this.state.dates[0]), {days: 1}), 'yyyy-MM-dd');

                if(format(new Date(tomorrow), 'i') === '7'){
                    tomorrow = format(add(new Date(tomorrow), {days: 1}), 'yyyy-MM-dd');
                }

                newDates = [tomorrow, tomorrow];
                this.setState({dates: newDates})
            break;

            case "currentWeek":
                const dates = this.setDates();
                this.setState({dates: dates});
                newDates = [dates[0], dates[1]];
            break;

            case "nextWeek":
                startDate = format(add(new Date(this.state.dates[0]), {weeks: 1}), 'yyyy-MM-dd');
                endDate = format(add(new Date(this.state.dates[1]), {weeks: 1}), 'yyyy-MM-dd');
                this.setState({dates: [startDate, endDate]});
                newDates = [startDate, endDate];
            break;

            case "prevWeek":
                startDate = format(subWeeks(new Date(this.state.dates[0]), 1), 'yyyy-MM-dd');
                endDate = format(subWeeks(new Date(this.state.dates[1]), 1), 'yyyy-MM-dd');
                this.setState({dates: [startDate, endDate]});
                newDates = [startDate, endDate];

            break;
        }

        localStorage.setItem('planningDates', newDates.join(','));
        this.selectAfspraken(newDates);
    }

    deleteAfspraak = (id) => {

        const self = this;

        Request.Call('/Planning/Afspraken/'+id, 'delete', null, (response) => {
            toast.success('Afspraak is verwijderd.');
            self.selectAfspraken();
        });
    }

    render(){
        return (
            <div className="planning">
                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    <Title title="Planning" size="3"></Title>
                    {
                        AuthUser('editPlanning') &&
                            <FontAwesomeIcon className="plusIcon" icon="plus" size="lg" onClick={this.addAfspraak}/>
                    }
                </div>
                {
                    isMobile() ?
                        <>
                            <div className="container">
                            <Button onClick={(e) => this.setDateFilter('yesterday')} variant="contained" sx={{ mr: 1, mt: 1, mb: 1 }}>Vorige</Button>
                                <Button onClick={(e) => this.setDateFilter('today')} variant="contained" sx={{ mr: 1, mt: 1, mb: 1 }}>Vandaag</Button>
                                <Button onClick={(e) => this.setDateFilter('tomorrow')} variant="contained" sx={{ mr: 1, mt: 1, mb: 1 }}>Volgende</Button>
                            </div>
                            <MobileCalendar startDate={this.state.dates[0]} endDate={this.state.dates[1]} data={this.state.calendar} addAfspraak={this.addAfspraak}></MobileCalendar>
                        </>
                    :
                        <>
                            <div className="container">
                                <Button onClick={(e) => this.setDateFilter('prevWeek')} variant="contained" sx={{ mr: 2, mt: 3, mb: 2 }}>Vorige week</Button>
                                <Button onClick={(e) => this.setDateFilter('currentWeek')} variant="contained" sx={{ mr: 2, mt: 3, mb: 2 }}>Vandaag</Button>
                                <Button onClick={(e) => this.setDateFilter('nextWeek')} variant="contained" sx={{ mr: 2, mt: 3, mb: 2 }}>Volgende week</Button>
                            </div>
                            <Calendar deleteAfspraak={this.deleteAfspraak} startDate={this.state.dates[0]} endDate={this.state.dates[1]} data={this.state.calendar} addAfspraak={this.addAfspraak} updateAfspraak={this.updateAfspraak}></Calendar>
                        </>
                }
            </div>
        )
    }
}

export default Planning;