import React from "react";
import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import Overview from "../../components/Overview"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import * as Request from "../../components/Call";
import history from '../../components/history';
import { format, parseISO  } from 'date-fns'
import { Button } from "@mui/material";
import * as Auth from '../../components/Auth/Auth';
import OverzichtFacturatie from "./Overview";
import CustomDialog from "../../components/Dialog"
import DateRangePickerInp from "../../components/Inputs/DateRangePicker";
import Filters from "../../components/Overview/Filters";
import { toast } from 'react-toastify';

class Facturatie extends React.Component {

    constructor(props){
        
        super(props);

        const mergedWerkbonnenAfgerond = (sessionStorage.getItem('view-gekoppelde-werkbonnen-afgerond')) ? JSON.parse(sessionStorage.getItem('view-gekoppelde-werkbonnen-afgerond')) : false;
        const werkbonnenAfgerond = (sessionStorage.getItem('view-werkbonnen-afgerond')) ? JSON.parse(sessionStorage.getItem('view-werkbonnen-afgerond')) : false;
        
        this.state = {
            mergedWerkbonnen: {
                data: [], 
                columns: [
                    {label: '', name: 'collapseExpandIcon', type: 'icon', size: 'lg', action: this.collapseExpandWerkbon, display: false},
                    {label: '', name: 'address'},
                    {label: '', name: 'date'}
                ],
                icons: [
                    {name: 'check', size: 'lg', position: 'after', action: this.completeWerkbonnen},
                ],
                filters: [
                    {name: 'searchField', label: 'Adres', value: '', type: 'text', filterType: 'like'},
                    {name: 'date', label: 'Datum', value: [null, null], type: 'dateRange',}
                ],
                limit: 10 ,
                loading: true,
                page: 1,
                total: 0,
                totalPages: 0,
                afgerond: mergedWerkbonnenAfgerond,
            },
            werkbonnen: {
                data: [], 
                columns: [
                    {label: '', name: 'address'},
                    {label: '', name: 'werkomschrijving'},
                    {label: '', name: 'datum'}
                ],
                icons: [
                    {name: 'check', size: 'lg', position: 'after', action: this.completeLosseWerkbonnen},
                    {name: 'trash', size: 'lg', position: 'after', action: this.deleteLosseWerkbon},
                    {name: 'trash', size: 'lg', position: 'after', action: this.deleteLosseWerkbon},
                    {name: 'arrows-alt', size: 'lg', position: 'before', onDragStart: true}
                ],
                filters: [
                    {name: 'searchField', label: 'Adres', value: '', type: 'text', filterType: 'like'},
                    {name: 'date', label: 'Datum', value: [null, null], type: 'dateRange'}
                ],
                limit: 10,
                loading: true,
                page: 1,
                total: 0,
                totalPages: 0,
                afgerond: werkbonnenAfgerond,
            },
            subData: false,
            deleteMergedWerkbonDialog: false,
            CompleetWerkbonDialog: false,
            deleteWerkbonDialog: false,
            CompleetLosseWerkbonDialog: false,
            CompleetRowIndex: 0,
            deleteRowIndex: 0,
            deleteparentIndex: 0,
            undoWerkbonIndex: -1,
            showFilters: '',
        };       
    }

    componentDidMount() {
        document.addEventListener('dragover', (e) => {
            if(e.y < 80){
                window.scrollBy({top: -150, behavior: 'smooth'});
            }

            if((window.innerHeight - 80) < e.y){
                window.scrollBy({top: 150, behavior: 'smooth'});
            }
        });
    }

    deleteWerkbon = (rowIndex, parentIndex) => {
        this.setState({deleteRowIndex: rowIndex, deleteparentIndex: parentIndex, deleteMergedWerkbonDialog: true});        
    }

    editWerkbon = (rowIndex, parentIndex) => {
        history.push({pathname: 'Werkbonnen/'+this.state.mergedWerkbonnen.data[parentIndex].subTable.data[rowIndex]['id'], state: { from: '/Facturatie' }});
    }

    deleteWerkbonDeclined = () => {
        this.setState({deleteRowIndex: 0, deleteparentIndex: 0, deleteMergedWerkbonDialog: false, deleteWerkbonDialog: false, CompleetWerkbonDialog: false, CompleetLosseWerkbonDialog: false});        
    }

    deleteLosseWerkbon = (rowIndex) => {
        this.setState({deleteRowIndex: rowIndex, deleteWerkbonDialog: true});
    }

    deleteWerkbonConfirmed = () => {

        const self = this;
        const orignalState = this.state;      
        orignalState.deleteDialog = false;  
        orignalState.deleteMergedWerkbonDialog = false;
        const werkbonId = orignalState.mergedWerkbonnen.data[this.state.deleteparentIndex].subTable.data[this.state.deleteRowIndex]['id'];
        orignalState.mergedWerkbonnen.data[this.state.deleteparentIndex].subTable.data = this.state.mergedWerkbonnen.data[this.state.deleteparentIndex].subTable.data.filter(
            (subRow, i) => i !== this.state.deleteRowIndex
        );     

        const url = '/Facturatie/Gekoppelde-werkbonnen/' + werkbonId;
        Request.Call(url, 'delete', false, function(response){
            toast.success('Werkbon is uit de lijst verwijderd.');
            self.setState(orignalState);
            self.getWerkbonnen([], 1);
        });   
    }

    deleteLosseWerkbonConfirmed = () => {

        const werkbonId = this.state.werkbonnen.data[this.state.deleteRowIndex]['id'];
        const self = this;
        const url = '/Facturatie/Werkbonnen/' + werkbonId;

        Request.Call(url, 'delete', false, function(response){
            toast.success('Werkbon is uit de lijst verwijderd.');
            self.getWerkbonnen([], 1);
            self.deleteWerkbonDeclined();
        });       
    }

    collapseExpandWerkbon = (rowIdx) => {

        const state = this.state.mergedWerkbonnen.data[rowIdx]['collapseExpandIcon'];
        const orignalState = this.state;

        if(state === 'angle-down'){
            orignalState.mergedWerkbonnen.data[rowIdx]['collapseExpandIcon'] = 'angle-up';
            orignalState.mergedWerkbonnen.data[rowIdx]['subTable']['show'] = true;
            orignalState.subData = rowIdx;
        }else{
            orignalState.mergedWerkbonnen.data[rowIdx]['collapseExpandIcon'] = 'angle-down';
            orignalState.mergedWerkbonnen.data[rowIdx]['subTable']['show'] = false;
            orignalState.subData = rowIdx;
        }

        this.setState(orignalState);
    }

    completeWerkbonnen = (id) => {
        this.setState({CompleetRowIndex: id, CompleetWerkbonDialog: true});
    }

    completeLosseWerkbonnen = (id) => {
        this.setState({CompleetRowIndex: id, CompleetLosseWerkbonDialog: true});
    }

    getWerkbonIds = (parentId, data) => {

        const ids = [parentId];

        data.map((value, key) => {
            if(ids.indexOf(value.id) < 0){
                ids.push(value.id);
            }
        });

        return ids;
    }

    compleetWerkbonConfirmed = () => {       

        const self = this;
        const id = this.state.mergedWerkbonnen.data[this.state.CompleetRowIndex]['id'];
        const subTableData = this.state.mergedWerkbonnen.data[this.state.CompleetRowIndex]['subTable']['data'];
        const url = '/Facturatie/Gekoppelde-werkbonnen/Afronden';
        const postBody = {ids: this.getWerkbonIds(id, subTableData)}

        Request.Call(url, 'post', postBody, function(response){
            toast.success('Werkbon is afgerond.');
            self.getMergedWerkbonnen([], 1);
            self.setState({CompleetRowIndex: 0, CompleetWerkbonDialog: false});
        });
    }

    compleetLosseWerkbonConfirmed = () => {       

        const self = this;
        const id = this.state.werkbonnen.data[this.state.CompleetRowIndex]['id'];
        const url = '/Facturatie/Werkbonnen/Afronden';
        const postBody = {id: id}

        Request.Call(url, 'post', postBody, function(response){
            toast.success('Werkbon is afgerond.');
            self.getWerkbonnen([], 1);
            self.setState({CompleetRowIndex: 0, CompleetLosseWerkbonDialog: false});
        });
    }

    rowClickMerged = (id) => {
        alert(id)
    }

    rowClickWerkbon = (id) => {
        history.push('/Facturatie/' + id);
    }

    openWerkbon = (id) => {
        history.push('/Werkbonnen/' + id);
    }

    dragDrop = (rowIndex, parentRowIndex) => {

        const self = this;
        const orignalState = this.state;
        const currentId = orignalState.werkbonnen.data[rowIndex]['id'];
        const parentId = orignalState.mergedWerkbonnen.data[parentRowIndex]['id'];

        orignalState.mergedWerkbonnen.data[parentRowIndex].subTable.data.push(orignalState.werkbonnen.data[rowIndex]);

        orignalState.werkbonnen.data = orignalState.werkbonnen.data.filter(
            (subRow, i) => i !== rowIndex
        );

        const url = '/Facturatie/Werkbonnen/mergeWerkbon';
        Request.Call(url, 'post', {parentWerkbonId: parentId, currentWerkbonId: currentId}, function(response){
            self.setState({orignalState});
        });        
    }

    handleOverviewFilter = (filters, page, type) => {

        let mappedFilters = [];
        let filter;

        filters.map((item, key) => {

            switch(item.type){

                default:
                    if(item.value){
                        filter = item.name+'='+item.value;
                        mappedFilters.push(filter);
                    }
                break;
            }
        });

        switch(type){

            case "mergedWerkbonnen":
                this.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, filters: mappedFilters, page: page}}));
                this.getMergedWerkbonnen(mappedFilters, page);
            break;

            case "werkbonnen":
                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, filters: mappedFilters, page: page}}));
                this.getWerkbonnen(mappedFilters, page);
            break;
        }
    }

    getMergedWerkbonnen = (filters, page, limit = false, afgerond = null) => {

        const self = this;
        const joinedFilters = filters.join('&');
        let url;
        limit = (limit) ? limit : this.state.mergedWerkbonnen.limit;
        afgerond = (afgerond !== null) ? afgerond : this.state.mergedWerkbonnen.afgerond;

        sessionStorage.setItem('view-gekoppelde-werkbonnen-afgerond', afgerond);

        if(filters){
            url = '/Facturatie/Gekoppelde-werkbonnen/Overview?'+joinedFilters+'&page='+page+'&limit='+limit+'&afgerond='+afgerond
        }else{
            url = '/Facturatie/Gekoppelde-werkbonnen/Overview?page='+page+'&limit='+limit+'&afgerond='+afgerond;
        }

        self.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, loading: true} }));

        Request.Call(url, 'get', null, function(response){
            
            let data = [];

            response.data.data.map((val) => {
                
                let datum = (val.date) ? format(parseISO(val.date), 'dd-MM-yyyy') : null;

                val.subTable.deleteWerkbon = self.deleteWerkbon;
                val.subTable.editWerkbon = self.editWerkbon;
                val.subTable.data.map((v,k) => {
                    val.subTable.data[k]['datum'] = (v.date) ? format(parseISO(v.date), 'dd-MM-yyyy') : null;
                });

                data.push({id: val.id, address: val.address, date: datum, collapseExpandIcon: val.collapseExpandIcon, subTable: val.subTable});
                return false;
            });

            const totalPages = Math.ceil(response.data.total/self.state.mergedWerkbonnen.limit);

            if(!afgerond){
                self.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, icons: [{name: 'check', size: 'lg', position: 'after', action: self.completeWerkbonnen}], data: data, total: response.data.total, totalPages: totalPages, loading: false, afgerond: afgerond}}));
            }else{
                self.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, data: data, total: response.data.total, totalPages: totalPages, loading: false, afgerond: afgerond, icons: [{name: 'times', size: 'lg', position: 'after', action: self.undoWerkbonnen}]}}));
            }
        });
    }

    getWerkbonnen = (filters, page, limit, afgerond = null) => {
       
        const self = this;
        const joinedFilters = filters.join('&');
        let url;
        limit = (limit) ? limit : this.state.werkbonnen.limit;
        afgerond = (afgerond !== null) ? afgerond : this.state.werkbonnen.afgerond;

        sessionStorage.setItem('view-werkbonnen-afgerond', afgerond);

        if(filters){
            url = '/Facturatie/Werkbonnen/Overview?'+joinedFilters+'&page='+page+'&limit='+limit+'&afgerond='+afgerond
        }else{
            url = '/Facturatie/Werkbonnen/Overview?page='+page+'&limit='+limit+'&afgerond='+afgerond;
        }

        self.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, loading: true} }));

        

        Request.Call(url, 'get', null, function(response){
            
            let data = [];

            response.data.data.map((val) => {
                let datum = (val.date) ? format(parseISO(val.date), 'dd-MM-yyyy') : null;
                data.push({id: val.id, address: val.address, werkomschrijving: val.werkomschrijving, datum: datum});
                return false;
            });

            const totalPages = Math.ceil(response.data.total/self.state.werkbonnen.limit);

            if(!afgerond){
                self.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, icons: [
                    {name: 'check', size: 'lg', position: 'after', action: self.completeLosseWerkbonnen},
                    {name: 'trash', size: 'lg', position: 'after', action: self.deleteLosseWerkbon},
                    {name: 'arrows-alt', size: 'lg', position: 'before', onDragStart: true}
                ], data: data, total: response.data.total, totalPages: totalPages, loading: false, afgerond: afgerond}})); 
            }else{
                self.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, data: data, total: response.data.total, totalPages: totalPages, loading: false, afgerond: afgerond, icons: [{name: 'times', size: 'lg', position: 'after', action: self.undoLosseWerkbonnen}]}})); 
            }
        });
    }

    prevNextPage = (type, prevNext) => {

        let page;

        switch(type){

            case "mergedWerkbonnen":

                if(prevNext === 'prev'){
                    if(this.state.mergedWerkbonnen.page === 1){
                        return;
                    }
                    page = (this.state.mergedWerkbonnen.page-1);
                }else{
                    if((this.state.mergedWerkbonnen.limit * this.state.mergedWerkbonnen.page) >= this.state.mergedWerkbonnen.total){
                        return;
                    }
                    page = (this.state.mergedWerkbonnen.page+1);
                }
               
                this.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, page: page}}));
                this.getMergedWerkbonnen(this.state.mergedWerkbonnen.filters, page);
            break;

            case "werkbonnen":

                if(prevNext === 'prev'){
                    if(this.state.werkbonnen.page === 1){
                        return;
                    }
                    page = (this.state.werkbonnen.page-1);
                }else{
                    if((this.state.werkbonnen.limit * this.state.werkbonnen.page) >= this.state.werkbonnen.total){
                        return;
                    }
                    page = (this.state.werkbonnen.page+1);
                }

                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, page: page}}));
                this.getWerkbonnen(this.state.werkbonnen.filters, page);
            break;
        }
    }

    changeLimit = (type, limit) => {

        switch(type){
            case "mergedWerkbonnen":
                this.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, limit: limit}}));
                this.getMergedWerkbonnen(this.state.mergedWerkbonnen.filters, 1, limit);
            break;

            case "werkbonnen":
                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, limit: limit}}));
                this.getWerkbonnen(this.state.werkbonnen.filters, 1, limit);
            break;
        }
    }

    undoWerkbonnen = (id) => {
        this.setState({CompleetRowIndex: id, undoWerkbonDialog: true});
    }

    undoLosseWerkbonnen = (id) => {
        this.setState({undoWerkbonIndex: id, undoWerkbonDialog: true});
    }

    undoWerkbonConfirmed = () => {

        const self = this;
        const url = '/Facturatie/Gekoppelde-werkbonnen/Afronden/Undo';
        let postBody;

        if(this.state.undoWerkbonIndex > -1){
            let currentWerkbon = this.state.werkbonnen.data[this.state.undoWerkbonIndex];
            postBody = {ids: currentWerkbon['id']}
        }else{
            let currentWerkbonnen = this.state.mergedWerkbonnen.data[this.state.CompleetRowIndex];
            postBody = {ids: this.getWerkbonIds(currentWerkbonnen['id'], currentWerkbonnen['subTable']['data'])}
        }

        Request.Call(url, 'post', postBody, function(response){
            toast.success('Werkbon(nen) zijn teruggeplaatst.');
            self.undoWerkbonDeclined();
            self.getMergedWerkbonnen(self.state.mergedWerkbonnen.filters, self.state.mergedWerkbonnen.page, self.state.mergedWerkbonnen.limit);
            self.getWerkbonnen(self.state.werkbonnen.filters, self.state.werkbonnen.page, self.state.werkbonnen.limit);
        });        
    }

    undoWerkbonDeclined = () => {
        this.setState({deleteRowIndex: 0, deleteparentIndex: 0, undoWerkbonIndex: -1, undoWerkbonDialog: false, deleteMergedWerkbonDialog: false, deleteWerkbonDialog: false, CompleetWerkbonDialog: false, CompleetLosseWerkbonDialog: false});    
    }

    getAfgerondeMergedWerkbonnen = () => {
        this.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, afgerond: true, icons: [{name: 'times', size: 'lg', position: 'after', action: this.undoWerkbonnen}]}}));
        this.getMergedWerkbonnen(this.state.mergedWerkbonnen.filters, 1, this.state.mergedWerkbonnen.limit, true);
    }

    getAfgerondeWerkbonnen = () => {
        this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, afgerond: true, icons: [{name: 'times', size: 'lg', position: 'after', action: this.undoLosseWerkbonnen}]}}));
        this.getWerkbonnen(this.state.werkbonnen.filters, 1, this.state.werkbonnen.limit, true);
    }

    changePage = (type, page) => {

        switch(type){
            case "mergedWerkbonnen":
                this.setState( prevState => ({ mergedWerkbonnen: {...prevState.mergedWerkbonnen, page: page}}));
                this.getMergedWerkbonnen(this.state.mergedWerkbonnen.filters, page, this.state.mergedWerkbonnen.limit);
            break;

            case "werkbonnen":
                this.setState( prevState => ({ werkbonnen: {...prevState.werkbonnen, page: page}}));
                this.getWerkbonnen(this.state.werkbonnen.filters, page, this.state.werkbonnen.limit);
            break;
        }
    }

    render(){

        return (
            <div className="overzicht"> 
                <CustomDialog open={this.state.deleteMergedWerkbonDialog} title="Verwijderen" content={"Weet je zeker dat je deze werkbon uit de lijst wilt verwijderen?"} buttons={[{title: 'Ja', onClick: this.deleteWerkbonConfirmed}, {title: 'Nee', onClick: this.deleteWerkbonDeclined}]}/>  
                <CustomDialog open={this.state.CompleetWerkbonDialog} title="Werkbon afronden" content={"Weet je zeker dat je deze werkbon(nen) wilt afronden?"} buttons={[{title: 'Ja', onClick: this.compleetWerkbonConfirmed}, {title: 'Nee', onClick: this.deleteWerkbonDeclined}]}/>  
                <CustomDialog open={this.state.CompleetLosseWerkbonDialog} title="Werkbon afronden" content={"Weet je zeker dat je deze werkbon wilt afronden?"} buttons={[{title: 'Ja', onClick: this.compleetLosseWerkbonConfirmed}, {title: 'Nee', onClick: this.deleteWerkbonDeclined}]}/>  
                <CustomDialog open={this.state.deleteWerkbonDialog} title="Werkbon verwijderen" content={"Weet je zeker dat je deze werkbon wilt verwijderen?"} buttons={[{title: 'Ja', onClick: this.deleteLosseWerkbonConfirmed}, {title: 'Nee', onClick: this.deleteWerkbonDeclined}]}/> 
                <CustomDialog open={this.state.undoWerkbonDialog} title="Werkbon terugplaatsen" content={"Weet je zeker dat je deze werkbonnen wilt terugplaatsen naar de openstaande werkbonnen lijst?"} buttons={[{title: 'Ja', onClick: this.undoWerkbonConfirmed}, {title: 'Nee', onClick: this.undoWerkbonDeclined}]}/> 

                <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                <div className="pageTitle">
                    <Title title="Facturatie overzicht" size="3"></Title>
                </div> 

                <div className="preset-filters">
                    <Button className={`button ${(!this.state.mergedWerkbonnen.afgerond === true) ? 'selected' : 'disabled'}`}  onClick={e => {this.getMergedWerkbonnen(this.state.mergedWerkbonnen.filters, 1, false, false)}} variant="contained">Openstaande werkbonnen</Button>
                    <Button className={`button ${(this.state.mergedWerkbonnen.afgerond === true) ? 'selected' : 'disabled'}`} selected={this.state.mergedWerkbonnen.afgerond} onClick={e => {this.getAfgerondeMergedWerkbonnen()}} variant="contained">Afgerond</Button>
                    <Filters name="facturatie-gekoppelde-werkbonnen" onChange={this.handleOverviewFilter} filterType="mergedWerkbonnen" filters={this.state.mergedWerkbonnen.filters}></Filters>
                </div>
                
                <div className="gekoppelde-werkbonnen">
                    <Title title="Gekoppelde werkbonnen" size="4"></Title>
                    <OverzichtFacturatie data={this.state.mergedWerkbonnen.data} columns={this.state.mergedWerkbonnen.columns} icons={this.state.mergedWerkbonnen.icons} dragDrop={this.dragDrop} rowClick={this.rowClickWerkbon} loading={this.state.mergedWerkbonnen.loading} prevPage={() => this.prevNextPage('mergedWerkbonnen', 'prev')} nextPage={() => this.prevNextPage('mergedWerkbonnen', 'next')} totalPages={this.state.mergedWerkbonnen.totalPages} changePage={(e) => this.changePage('mergedWerkbonnen', e.target.value)} changeLimit={(e) => this.changeLimit('mergedWerkbonnen', e.target.value)} currentPage={this.state.mergedWerkbonnen.page}></OverzichtFacturatie>
                </div>

                <div className="losse-werkbonnen">
                    <div className="overviewHeader">
                        <div className="title">
                            <Title title="Losse werkbonnen" size="4"></Title>
                        </div>
                        <div className="preset-filters">
                            <Button className={`button ${(!this.state.werkbonnen.afgerond === true) ? 'selected' : 'disabled'}`}  onClick={e => {this.getWerkbonnen(this.state.werkbonnen.filters, 1, this.state.werkbonnen.limit, false)}} variant="contained">Openstaande werkbonnen</Button>
                            <Button className={`button ${(this.state.werkbonnen.afgerond === true) ? 'selected' : 'disabled'}`} selected={this.state.werkbonnen.afgerond} onClick={e => {this.getAfgerondeWerkbonnen()}} variant="contained">Afgerond</Button>
                            <Filters name="facturatie-werkbonnen" onChange={this.handleOverviewFilter} filterType="werkbonnen" filters={this.state.werkbonnen.filters}></Filters>
                        </div>
                    </div>

                    <OverzichtFacturatie data={this.state.werkbonnen.data} columns={this.state.werkbonnen.columns} icons={this.state.werkbonnen.icons} draggAble loading={this.state.werkbonnen.loading} prevPage={() => this.prevNextPage('werkbonnen', 'prev')} nextPage={() => this.prevNextPage('werkbonnen', 'next')} totalPages={this.state.werkbonnen.totalPages} changePage={(e) => this.changePage('werkbonnen', e.target.value)} changeLimit={(e) => this.changeLimit('werkbonnen', e.target.value)} currentPage={this.state.werkbonnen.page} rowClick={this.openWerkbon}></OverzichtFacturatie>
                </div>
            </div>
        )
    }
}

export default Facturatie