import Breadcrumb from "../../components/Menu/Breadcrumb"
import Title from "../../components/Title"
import React from "react";
import * as Request from "../../components/Call";
import { TextField, Button, Checkbox } from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import history from '../../components/history';
import CustomDialog from "../../components/Dialog"
import { toast } from 'react-toastify';

class Gebruiker extends React.Component {

    constructor(props){
        
        super(props);

        const gebruikerId = (props.match) ? props.match.params.gebruikerId : null

        this.state = {
            userDetails: {firstname: '', lastname: '', password: '', username: '', active: true},
            roles: {edit_users: false, edit_materiaal: false, werkbonnen_all: false, post_afspraak: false, checklist_all: false, checklist: false, facturatie: false, logging: false},
            gebruikerId: gebruikerId,
            deleteDialog: false,
        };     
    }

    componentDidMount(prevProps, prevState) {
        if(!this.state.gebruikerId) return;
        this.getUserById(this.state.gebruikerId)
    }

    getUserById = (id) => {

        const self = this;

        Request.Call('/Gebruikers/' + id, 'get', null, function(response){
            
            if(response.data.roles){
                const roles = response.data.roles.split(',');
                let rolesMapped = {};

                roles.map((role) => {
                    rolesMapped[role] = true;
                });
                
                self.setState({roles: rolesMapped});
            }

            self.setState({userDetails: response.data});
        });
    }

    handleChange = (event, stateType) => {
        switch(event.target.type){

            default:
                let value = event.target.value;
                this.setState( prevState => ({ userDetails: {...prevState.userDetails, [event.target.name]: value} }));
            break;

            case "checkbox":
                this.setState(prevState => ({ [stateType]: {...prevState[stateType], [event.target.name]: (!this.state[stateType][event.target.name])}}));
            break;
        }
    }

    saveUserDetails = (e) => {

        e.preventDefault();

        const requestBody = {userDetails: this.state.userDetails, roles: this.state.roles};
        
        if(this.state.gebruikerId){
            Request.Call('/Gebruikers/' + this.state.gebruikerId, 'put', requestBody, function(response){
                toast.success('Gebruiker is gewijzigd.');
                history.push('/Gebruikers');
            });
        }else{
            Request.Call('/Gebruikers', 'post', requestBody, function(response){
                toast.success('Gebruiker is aangemaakt.');
                history.push('/Gebruikers');
            });
        }
    }

    deleteUser = (e) => {
        this.setState({deleteDialog: true});
    }

    deleteUserConfirmed = (e) => {

        const self = this;

        const url = '/Gebruikers/' + this.state.gebruikerId;
        Request.Call(url, 'delete', false, function(response){
            toast.success('Gebruiker verwijderd.');
            self.setState({deleteDialog: false});
            history.push('/Gebruikers'); 
        });          
    }

    deleteDeclined = (e) => {
        this.setState({deleteDialog: false});   
    }

    render(){
        return (
            <Box className="details" onSubmit={this.saveUserDetails} component="form" noValidate sx={{ mt: 1 }}>     
            <CustomDialog open={this.state.deleteDialog} title="Gebruiker verwijderen" content={"Weet je zeker dat je deze gebruiker wilt verwijderen?"} buttons={[{title: 'Ja', onClick: this.deleteUserConfirmed}, {title: 'Nee', onClick: this.deleteDeclined}]}/>    
                <div className="overzicht">   
                    <Breadcrumb title="Terug" icon="arrow-left"></Breadcrumb>
                    <Title title={`Gebruiker aanpassen - ${this.state.userDetails.firstname}`} size="5" ></Title>
                    <div className="form-fields">
                        <div className="default-fields">
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="username"
                                label="Gebruikersnaam"
                                type="text"
                                variant="filled" 
                                value={this.state.userDetails.username}
                                onChange={(e) => this.handleChange(e, 'userDetails')}
                            />  
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="firstname"
                                label="Voornaam"
                                type="text"
                                variant="filled" 
                                value={this.state.userDetails.firstname}
                                onChange={(e) => this.handleChange(e, 'userDetails')}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="lastname"
                                label="Achternaam"
                                type="text"
                                variant="filled" 
                                value={this.state.userDetails.lastname}
                                onChange={(e) => this.handleChange(e, 'userDetails')}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Wachtwoord"
                                type="password"
                                variant="filled" 
                                value={this.state.userDetails.password}
                                onChange={(e) => this.handleChange(e, 'userDetails')}
                            />
                            <FormControlLabel
                                name="active"
                                control={<Checkbox name="active" onChange={(e) => this.handleChange(e, 'userDetails')} checked={this.state.userDetails.active}/>}
                                label="Actief"
                                
                                labelPlacement="end"
                            />
                            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>Opslaan</Button>
                            <Button onClick={this.deleteUser} fullWidth variant="contained" sx={{ mt: 3, mb: 2 }} style={{ backgroundColor: "var(--color-red)" }}>Verwijderen</Button>
                        </div>
                        <div className="autorisation">
                            <Title title='Rechten instellen' size="4" ></Title>
                            <FormControlLabel
                                name="edit_users"
                                control={<Checkbox name="edit_users" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.edit_users}/>}
                                label="Gebruikers bewerken"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                name="edit_materiaal"
                                control={<Checkbox name="edit_materiaal" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.edit_materiaal}/>}
                                label="Materiaal bewerken"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                name="werkbonnen_all"
                                control={<Checkbox name="werkbonnen_all" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.werkbonnen_all}/>}
                                label="Alle werkbonnen tonen"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                name="post_afspraak"
                                control={<Checkbox name="post_afspraak" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.post_afspraak}/>}
                                label="Planning bewerken"
                                labelPlacement="end"
                            />
                            
                            <FormControlLabel
                                name="checklist"
                                control={<Checkbox name="checklist" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.checklist}/>}
                                label="Checklijsten tonen"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                name="checklist_all"
                                control={<Checkbox name="checklist_all" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.checklist_all}/>}
                                label="Alle checklijsten tonen"
                                labelPlacement="end"
                            />

                            <FormControlLabel
                                name="facturatie"
                                control={<Checkbox name="facturatie" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.facturatie}/>}
                                label="Facturatie tonen"
                                labelPlacement="end"
                            />

                            <FormControlLabel
                                name="logging"
                                control={<Checkbox name="logging" userType="roles" onChange={(e) => this.handleChange(e, 'roles')} checked={this.state.roles.logging}/>}
                                label="Logging tonen"
                                labelPlacement="end"
                            />
                        </div>
                    </div>

                    
                </div>
            </Box>
        )
    }
}

export default Gebruiker;



